const menu = [
  {
    icon: "play-circle",
    text: "Videos",
    link: "/videos",
    dashboard: true,
  },
  {
    icon: "dashboard",
    text: "Channels",
    link: "/channels",
    dashboard: true,
  },
  {
    icon: "upload",
    text: "Uploads",
    link: "/uploads",
    dashboard: true,
  },
  {
    icon: "video",
    text: "Live Streams",
    link: "/live-streams",
    dashboard: true,
  },
  // {
  //   icon: "exchange",
  //   text: "Encode Video",
  //   link: "/encode-video",
  //   dashboard: true,
  // },
  {
    icon: "globe",
    text: "My Network",
    link: "/websites",
    dashboard: true,
  },
  {
    icon: "help",
    text: "Help",
    link: "/help",
    dashboard: true,
  },
  // {
  //   icon: "chart-up",
  //   text: "Analytics",
  //   link: "/analytics",
  //   dashboard: true,
  // },

  // {
  //   heading: "Pre-built Pages",
  // },
  // {
  //   icon: "tile-thumb",
  //   text: "Projects",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Project Cards",
  //       link: "/project-card",
  //     },
  //     {
  //       text: "Project List",
  //       link: "/project-list",
  //     },
  //   ],
  // }
];
export default menu;
