import { getCookie } from "../../utils/Utils";

export const getAllFollowers = async () => {
  try {
    const formData = new FormData();
    formData.append("type", "followers");
    const response = await fetch(`${process.env.REACT_APP_API_URL_Logo}api/v1/connection/users`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken"),
      },
      body: formData,
    });
    return await response.json();
  } catch (error) {
    throw new Error("Something went wrong" + error);
  }
};
export const sendLiveNotification = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}send-live-notification-to-connected-user`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken"),
      },
    });
    return await response.json();
  } catch (error) {
    throw new Error("Something went wrong" + error);
  }
};
export const getConnectedUser = async (search) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}get-user-connections?search=${search}`, {
      method: "GET",
      headers: {
        authToken: getCookie("authToken"),
      },
    });
    return await response.json();
  } catch (error) {
    throw new Error("Something went wrong" + error);
  }
};
export const getTallyFileData = async (channel_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}get-tally-file-data/${channel_id}`, {
      method: "GET",
      headers: {
        authToken: getCookie("authToken"),
      },
    });
    return await response.json();
  } catch (error) {
    throw new Error("Something went wrong" + error);
  }
};
export const sendMailToConnect = async (user_ids) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}send-mail-to-connection`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authToken: getCookie("authToken"),
      },
      body: JSON.stringify({
        user_ids: user_ids,
      }),
    });
    return await response.json();
  } catch (error) {
    throw new Error("Something went wrong" + error);
  }
};
