import React, { useEffect, createContext, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { useDispatch, useSelector } from "react-redux";

import "../css/streamdeck_css/streamdeck.css";
import "../css/custom.scss";

import { ToastContainer } from "react-toastify";

import { useTheme } from "./provider/Theme";
import { StreamAddContext } from "../pages/app/StremContext";
import { HeaderTextProvider } from "../pages/HeaderTextContext";
import { ScheduledChannelProvider } from "../pages/ScheduledChannelContext";
import { WebcamSettingsProvider } from "../pages/WebcamSettingsContext";
import { SessionProvider } from "../pages/SessionContext";
import { StreamProvider } from "../pages/StreamContext";
import { muiAction } from "../store/slices/mui-slice";
import { io } from "socket.io-client";
import Cookies from "js-cookie";
import { Spinner } from "reactstrap";
import KeyboardLayout from "../pages/KeyboardLayout";
import livenotificationicon from "../images/notificationImages/LiveNotificatioIcon.png";
import livenotificationimage from "../images/notificationImages/LiveNotificationImage.png";
import { getCookie } from "../utils/Utils";
export const GloabalContext = createContext();

const Layout = ({ title, app, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shortcutModalOpen, setShortcutModalOpen] = useState(false); // State to manage modal visibility

  const dispatch = useDispatch();
  const muiTheme = useSelector((state) => state.mui.theme);
  const theme = useTheme();

  const [files, setFiles] = useState([]);
  const location = useLocation();
  const [activestreamkey, setactiveStreamKey] = useState("");
  const [streamkey, setStreamKey] = useState("");
  const [createChannel, setCreateChannel] = useState(false);
  const [playback_url_key, setChannelKey] = useState("");
  const [stream_id, setStreamId] = useState("");

  const channelUrlType = "0";
  const channelinearype = "0";
  const [formData, setFormData] = useState({
    channel_type: channelUrlType,
    linear_channel_type: channelinearype,
    channel_name: "Untitled Channel",
    schedule_duration: "0",
    start_time: new Date(),
    logo: "",
    logo_link: "",
    logo_position: "0",
    logo_on_off: "1",
    main_color: "878796",
    channel_embedded: "0",
    add_tag_url: [],
    channel_data_id: "",
  });

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#666" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "/") {
        event.preventDefault();
        setShortcutModalOpen(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const username = getCookie("username");
  const moduleName = process.env.REACT_APP_MODULE_NAME;
  // const socket = io("https://live.silocloud.io");
  // // console.log(`notification_${moduleName}_${username}`);

  // useEffect(() => {
  //   socket.on(`notification_${moduleName}_${username}`, (data) => {
  //     if (data) {
  //       if ("Notification" in window) {
  //         if (Notification.permission === "granted") {
  //           new Notification(`Notification: ${data.title}`, {
  //             body: `${data.body}`,
  //             icon: `${process.env.REACT_APP_API_URL_Logo}logo/apps/silo-streamdeck.png`,
  //           });
  //         } else if (Notification.permission !== "denied") {
  //           Notification.requestPermission().then((permission) => {
  //             if (permission === "granted") {
  //               new Notification(`Notification: ${data.title}`, {
  //                 body: `${data.body}`,
  //                 icon: `${process.env.REACT_APP_API_URL_Logo}logo/apps/silo-streamdeck.png`,
  //               });
  //             }
  //           });
  //         }
  //       } else {
  //         console.log("Browser does not support notifications.");
  //       }
  //     }
  //   });

  //   return () => {
  //     socket.off(`notification_${moduleName}_${username}`);
  //   };
  // }, []);
  if (isLoading) {
    return (
      <div className="loader-spinner-custom d-flex justify-content-center align-items-center">
        <Spinner color="dark" />
      </div>
    );
  }

  return (
    <>
      <SessionProvider>
        <StreamProvider>
          <StreamAddContext.Provider
            value={{
              streamkey,
              setStreamKey,
              playback_url_key,
              setChannelKey,
              stream_id,
              setStreamId,
              createChannel,
              setCreateChannel,
              formData,
              setFormData,
            }}
          >
            <GloabalContext.Provider value={{ files, setFiles, activestreamkey, setactiveStreamKey }}>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <Head title={!title && "StreamDeck"} />

              <HeaderTextProvider>
                <ScheduledChannelProvider>
                  <WebcamSettingsProvider>
                    <AppRoot>
                      <Appbar />
                      <AppMain>
                        <AppWrap className={`${location.pathname === "/channel-view" ? "bg-black" : ""}`}>
                          <Header fixed />
                          <KeyboardLayout isOpen={shortcutModalOpen} toggle={() => setShortcutModalOpen(false)} />
                          <Outlet />
                          {location.pathname !== "/schedular" && location.pathname !== "/schedule-channel/timeline" && (
                            <Footer />
                          )}
                        </AppWrap>
                      </AppMain>
                    </AppRoot>
                  </WebcamSettingsProvider>
                </ScheduledChannelProvider>
              </HeaderTextProvider>
            </GloabalContext.Provider>
          </StreamAddContext.Provider>
        </StreamProvider>
      </SessionProvider>
    </>
  );
};
export default Layout;
