import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Tooltip,
  Alert,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Upload from "../Upload/Upload";
import { Icon, UserAvatar } from "../../components/Component";
import Delete from "../channel/Delete";
import FilterMenu from "../app/FilterMenu";
import axios from "axios";
import { getCookie } from "../../utils/Utils";
import Swal from "sweetalert2";
import InfiniteScroll from "react-infinite-scroll-component";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";
import { apiRequest } from "../../utils/apiHelper";
import { ShimmerSimpleGallery, ShimmerThumbnail } from "react-shimmer-effects";
import TooltipItem from "../channel/TooltipItem";
import { useScreenSize } from "../hooks/useScreenSize";
import { MediaPlayer, MediaProvider, Poster } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import defaultImage from "../../images/StreamDeckThumbnail.png";
import KeyboardShortcut from "../KeyboardShortcut";
import Tour from "reactour";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const Video = () => {
  const [learnmore, setlearmore] = useState(false);
  const togglelearnmore = () => setlearmore(!learnmore);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoUrls, setVideoUrls] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [tagQuery, setTagQuery] = useState([]);
  const [sortingOption, setSortingOption] = useState(null);
  const [SortingOptionMedia, setSortingOptionMedia] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [videoStreams, setVideoStreams] = useState([]);
  const [selectedVideoStream, setSelectedVideoStream] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [isSecondaryModalOpen, setIsSecondaryModalOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedVideoStreamId, setSelectedVideoStreamId] = useState(null);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [total_records_count, totalRecordsCount] = useState("");
  const [selectedMatchedTag, setSelectedMatchedTag] = useState(null);
  const [VideoStreamsFiltered, setVideoStreamsFiltered] = useState(null);
  const [isFilter, setIsFilter] = useState(false);

  const [edit, setEdit] = useState(false);
  const [code, setCode] = useState(false);
  const [label, setLabel] = useState(false);
  const [download, setDownload] = useState(false);
  const [delet, setDelet] = useState(false);
  const [duration, setDuration] = useState(0);
  const [thumbnail, setThumbnail] = useState(false);
  const editToggle = () => setEdit(!edit);
  const codeToggle = () => setCode(!code);
  const labelToggle = () => setLabel(!label);
  const downloadToggle = () => setDownload(!download);
  const deletToggle = () => setDelet(!delet);

  useEffect(() => {
    if (selectedVideoStreamId !== null) {
      fetchVideoUrl(selectedVideoStreamId);
    }
    console.log("sortingOption", sortingOption);
  }, [selectedVideoStreamId]);

  useEffect(() => {
    fetchVideoStreams();
  }, [sortingOption, selectedMatchedTag]);

  useEffect(() => {
    fetchVideoStreams();
  }, [page]);
  useEffect(() => {
    console.log("selectedVideoStream", selectedVideoStream, tags);
    const updateTagsInDatabase = async () => {
      try {
        if (selectedVideoStream) {
          const { videoStreamid } = selectedVideoStream;
          const tagsString = tags.join(",");
          const response = await apiRequest(
            "update-video",
            "POST",
            { stream_id: videoStreamid, tags: tagsString },
            "application/json"
          );
        }
      } catch (error) {
        console.error("Error updating tags in the database:", error);
      }
    };
    updateTagsInDatabase();
  }, [selectedVideoStream, tags]);

  const handleMediaTypes = (media) => {
    // setPage(1);
  };

  useEffect(() => {
    setPage(1);
    fetchVideoStreams();
  }, [searchQuery, tagQuery]);

  const clearAllFilters = () => {
    setSearchQuery("");
    setTagQuery([]);
    setSortingOption(null);
    setSortingOptionMedia(null);
    setSelectedMatchedTag(null);
    setIsFilter(false);
  };

  const handleChange = (tags) => {
    setTags(tags);
  };
  const toggleDeleteModal = (videoStreamId) => {
    setIsModalOpen1(!isModalOpen1);
    setSelectedVideoStreamId(videoStreamId);
  };

  const toggleopen = () => {
    setIsOpen(!isOpen);
  };

  async function fetchVideoStreams() {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("page", page);
      const mapSortingMediaOptionToBackendValue = (frontendValue) => {
        switch (frontendValue) {
          case "Hosted Videos":
            return 1;
          case "External Links":
            return 1;
          default:
            return "default";
        }
      };
      const mapSortingOptionToBackendValue = (frontendValue) => {
        switch (frontendValue) {
          case "Newest first":
            return "newest";
          case "Oldest first":
            return "oldest";
          case "A → Z":
            return "az";
          case "Z → A":
            return "za";
          case "Shortest first":
            return "shortest";
          case "Longest first":
            return "longest";
          default:
            return "newest";
        }
      };
      if (SortingOptionMedia) {
        switch (SortingOptionMedia.value) {
          case "Hosted Videos":
            formData.append("hosted_video", 1);
            break;
          case "External Links":
            formData.append("external_link", 1);
            break;
          default:
            break;
        }
      }
      var requestBody;
      if (selectedMatchedTag && selectedMatchedTag.value === "Match all tags" && tagQuery.length > 0) {
        console.log("selectedMatchedTag", selectedMatchedTag);
        requestBody = JSON.stringify({ tags: tagQuery, alltags: 1, page: page });
      } else if (tagQuery.length > 0) {
        requestBody = JSON.stringify({ tags: tagQuery, page: page });
      } else {
        if (searchQuery.trim() !== "") {
          formData.append("title", searchQuery);
        }
        if (sortingOption !== null) {
          formData.append("order_by", mapSortingOptionToBackendValue(sortingOption.value));
        }
        if (SortingOptionMedia !== null) {
          if (SortingOptionMedia.value === "External Links") {
            formData.append("external_link", mapSortingMediaOptionToBackendValue(SortingOptionMedia.value));
          }
          if (SortingOptionMedia.value === "Hosted Videos") {
            formData.append("hosted_video", mapSortingMediaOptionToBackendValue(SortingOptionMedia.value));
          }
        }
        requestBody = formData;
      }
      const response = await apiRequest("get-all-video", "POST", requestBody, "application/json");
      console.log(response.data.status, page);
      if (!response.data.status && page === 1) {
        setVideoStreams([]);
        setHasMore(false);
        return;
      }
      if (response.data.data.videostreams && response.data.data.videostreams.length > 0) {
        totalRecordsCount(response.data.data.total_records_count);
        if (page === 1) {
          setVideoStreams(response.data.data.videostreams);
          setHasMore(false);
        } else {
          setVideoStreams((prevStreams) => [...prevStreams, ...response.data.data.videostreams]);
          setHasMore(false);
        }
        setPage((prevPage) => prevPage + 1);
        if (response.data.data.videostreams.length < 10) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching video streams", error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchVideoUrl = async (videoStreamId) => {
    try {
      const authToken = getCookie("authToken", null);
      let videoUrl;
      let duration;
      let thumbnail;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}get-video`,
        { video_id: videoStreamId },
        {
          headers: {
            authToken: authToken,
          },
          responseType: "json",
        }
      );

      if (response.data.data && response.data.data.video_url_data) {
        videoUrl = response.data.data.video_url;
      } else {
        const blobResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}get-video`,
          { video_id: videoStreamId },
          {
            headers: {
              authToken: authToken,
            },
            responseType: "json",
          }
        );
        videoUrl = blobResponse.data.data.videoStream;
        duration = blobResponse.data.data.duration;
        thumbnail = blobResponse.data.data.thumbnail;
      }
      setVideoUrls(videoUrl);
      setDuration(duration);
      setThumbnail(thumbnail);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching video URL", error);
      setIsLoading(false);
    }
  };

  const handleVideoDownload = async (download_path) => {
    try {
      Swal.fire({
        title: "Downloading...",
        text: "Please wait while the video is downloading.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const link = document.createElement("a");
      link.href = download_path;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      Swal.close();
    } catch (error) {
      console.error(error);
      Swal.fire("Error", "There was an issue downloading the file.", "error");
    }
  };

  const handleVideoDelete = async (video_stream_id) => {
    try {
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      });

      if (confirmed.isConfirmed) {
        const authToken = getCookie("authToken", null);
        const formData = new FormData();
        formData.append("video_stream_id", video_stream_id);
        const response = await apiRequest("delete-video", "POST", formData, "multipart/form-data");
        setPage(1);
        setVideoStreams((prevState) => prevState.filter((video) => video.id !== video_stream_id));
        toast.success("Deleted!", "Your file has been deleted.");
      }
    } catch (error) {
      console.error("Error deleting video", error);
      toast.error("Error!", "Failed to delete the file.");
    }
  };
  const navigate = useNavigate();
  const handleaddSchedule = async (video_id, video_uuid) => {
    console.log(video_id, video_uuid);
    try {
      const formData = new FormData();
      formData.append("video_id", video_id);
      const response = await fetch(`${process.env.REACT_APP_API_URL}add-to-scheduler`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.message}`);
      }
      const responseData = await response.json();
      if (responseData.status) {
        // fetchVideoStreams();
        toast.success("Video added to scheduler.");
        navigate("/schedule-channel/timeline");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidation = (url) => {
    if (!url) {
      return "";
    } else if (!url.toLowerCase().endsWith(".m3u8")) {
      return "Please enter a valid .m3u8 video URL.";
    } else {
      return "";
    }
  };

  const handleBlur = () => {
    setValidationError(handleValidation(videoUrl));
  };

  const handleKeyUp = (e) => {
    setValidationError(handleValidation(e.target.value));
  };

  const handleAddVideo = async () => {
    if (!videoUrl) {
      toast.error("Please add m3u8 video link");
      return;
    }
    try {
      const authToken = getCookie("authToken", null);
      const formData = new FormData();
      formData.append("video_url", videoUrl);
      const response = await apiRequest("add-video", "POST", formData, "multipart/form-data");
      if (response.data && response.data.status && response.data.status === true) {
        setSortingOptionMedia(SortingOptionMedia);
        toast.success(response.data.message);
        setPage(1);
        fetchVideoStreams();
        setVideoUrl("");
      } else {
        toast.error(response.data.message || "Failed to add video");
      }
    } catch (error) {
      console.error("Error adding video", error);
      toast.error(error);
    }
  };

  const [downloadvideo, setDownloadvideo] = useState(false);
  const [trash, settrash] = useState(false);
  const toggledownload = () => setDownloadvideo(!downloadvideo);
  const toggletrash = () => settrash(!trash);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const handleTagClick = (videoStream, videoStreamid, tags) => {
    setSelectedVideoStream({ videoStream, videoStreamid });
    setTags(tags || []);
    setIsModalOpen(true);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      setPage(1);
      fetchVideoStreams();
    }
  };

  const handleSearchChangefirst = (event) => {
    console.log(event);
    setSearchQuery(event);
  };

  const handleSortingChange = (selectedOption) => {
    setPage(1);
    setSortingOption(selectedOption);
  };
  const handleSearchChangeTagQuery = (selectedOption) => {
    setTagQuery(selectedOption);
  };

  const handleMediaTypeChange = (selectedOption) => {
    setPage(1);
    setSortingOptionMedia(selectedOption);
  };
  const handleMatchedTag = (MatchedTag) => {
    console.log("MatchedTag", MatchedTag);
    setPage(1);
    setSelectedMatchedTag(MatchedTag);
  };
  const [highlightedCards, setHighlightedCards] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);
  const handleCtrlClick = (event, videoStream) => {
    if (event.ctrlKey) {
      setHighlightedCards((prevState) =>
        prevState.includes(videoStream.id)
          ? prevState.filter((cardId) => cardId !== videoStream.id)
          : [...prevState, videoStream.id]
      );

      setSelectedVideos((prevState) => {
        const isSelected = prevState.some((video) => video.id === videoStream.id);
        if (isSelected) {
          return prevState.filter((video) => video.id !== videoStream.id);
        } else {
          return [...prevState, videoStream];
        }
      });

      setSelectedVideoIds((prevState) => {
        const isSelected = prevState.includes(videoStream.id);
        if (isSelected) {
          return prevState.filter((id) => id !== videoStream.id);
        } else {
          return [...prevState, videoStream.id];
        }
      });
    }
  };
  const screenSize = useScreenSize();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const handleaddScheduleMultiple = async () => {
    try {
      const formData = new FormData();
      formData.append("video_id", JSON.stringify(selectedVideoIds));
      const response = await fetch(`${process.env.REACT_APP_API_URL}add-to-scheduler`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.message}`);
      }
      const responseData = await response.json();
      if (responseData.status) {
        toast.success("Video added to scheduler.");
        navigate("/schedule-channel/timeline");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [Keyboardshorts, setKeyboardshorts] = useState(false);
  const showKeyboardshorts = () => {
    setKeyboardshorts(true);
    setTimeout(() => {
      setKeyboardshorts(false);
    }, 3000);
  };
  const ImageWithFallback = ({ src, alt, fallbackSrc }) => {
    const handleError = (event) => {
      event.target.src = fallbackSrc;
    };

    return <img src={src} alt={alt} onError={handleError} />;
  };
  const defaultImages = defaultImage;
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [buttonText, setButtonText] = useState("Copy Link");
  const [tooltipOpens, setTooltipOpens] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText("https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8")
      .then(() => {
        setButtonText("Copied");
        setTimeout(() => setButtonText("Copy Link"), 2000); // Reset button text after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const toggles = () => setTooltipOpens(!tooltipOpens);
  const [currentStep, setCurrentStep] = useState();
  console.log(currentStep);
  const setNotShowAgain = () => {
    Cookies.set("mediaTutorial", "true", { expires: 7 });
  };
  useEffect(() => {
    const tutorialSeen = Cookies.get("mediaTutorial");
    if (!tutorialSeen) {
      setIsTourOpen(true);
      // Cookies.set("mediaTutorial", "true", { expires: 7 });
    } else {
      setIsTourOpen(false);
    }
  }, []);
  const DoNotShow = () => {
    return (
      <div className="custom-control custom-control-sm custom-checkbox">
        <input type="checkbox" className="custom-control-input" id="customCheck7" onChange={(e) => setNotShowAgain()} />
        <label className="custom-control-label" htmlFor="customCheck7">
          Do not show again.
        </label>
      </div>
    );
  };
  const tourSteps = [
    {
      selector: ".medialibrary1",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            Congrats! Here is Your Uploaded Video,
          </p>
          <DoNotShow />
        </>
      ),
    },
    {
      selector: ".medialibraryupload",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            You Can Upload More Videos later.
          </p>
          <DoNotShow />
        </>
      ),
    },
    {
      selector: ".filter-menu-step",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            This Are Filter Options
          </p>
          <DoNotShow />
        </>
      ),
    },
    {
      selector: ".m3u8linkhelper",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            You can also upload m3u8 file in media library.
          </p>
          <small>
            click on <strong className="text-primary">Add Video URL</strong> buttton
          </small>{" "}
          <br />
          <DoNotShow />
          {/* <div className="mt-1">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value="https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
              />
              <div className="input-group-append">
                <Button className="bg-danger text-white" id="copym3u8" onClick={handleCopy}>
                  {buttonText}
                </Button>
                <Tooltip placement="top" isOpen={tooltipOpens} target="copym3u8" toggle={toggles}>
                  {buttonText === "Copy Link" ? "Copy" : "Copied"}
                </Tooltip>
              </div>
            </div>
          </div> */}
        </>
      ),
    },
    {
      selector: "",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            Congratulations! Now You Know Media Library <br />
            Let's Create First Channel
          </p>
          <DoNotShow />
        </>
      ),
    },
  ];
  useEffect(() => {
    if (currentStep === 2) {
      setVideoUrl(
        "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
      );
    }
  }, [currentStep]);
  // useEffect(() => {
  //   const tutorialSeen = Cookies.get("mediaTutorial");
  //   if (!tutorialSeen) {
  //     setIsTourOpen(true);
  //     // Cookies.set("mediaTutorial", "true", { expires: 7 });
  //   } else {
  //     setIsTourOpen(false);
  //   }
  // }, []);
  const finishMediaTutorial = () => {
    Cookies.set("mediaTutorial", "true", { expires: 7 });
    navigate("/channels");
  };
  return (
    <>
      <section className="m-3 mb-0">
        {Keyboardshorts && (
          <div className="w-25 slide-in-right" style={{ position: "fixed", top: "5rem", zIndex: 99, right: 10 }}>
            <Alert className="alert-icon" color="primary" fade>
              <Icon name="alert-circle" />
              You can use <kbd>ctrl</kbd> + <kbd>click</kbd> to select multiple videos
            </Alert>
          </div>
        )}
        {selectedVideos.length > 0 && (
          <div style={{ position: "fixed", right: 0, bottom: 62, zIndex: 99 }}>
            <Alert className="alert-pro" color="danger">
              <div className="alert-text d-flex">
                {selectedVideos.map((video, index) => (
                  <UserAvatar
                    key={index}
                    image={video.videothumbnails || defaultImage}
                    className={`sq ${
                      screenSize === "Extra Large" ? "md" : screenSize === "Small" ? "md" : "md"
                    } bg-transparent ms-1`}
                  />
                ))}
                <div onClick={handleaddScheduleMultiple} className="pointer">
                  <UserAvatar
                    icon="plus"
                    id="addtoscheduler"
                    className={`sq text-dark ${
                      screenSize === "Extra Large" ? "md" : screenSize === "Small" ? "md" : "md"
                    } bg-transparent ms-1 bg-danger`}
                  />
                </div>
                <Tooltip placement="top" isOpen={tooltipOpen} target="addtoscheduler" toggle={toggle}>
                  Add To Scheduler
                </Tooltip>
              </div>
            </Alert>
          </div>
        )}
        <div className="section-color p-3 pb-0 rounded-4">
          <div className="d-flex justify-content-between align-items-center gap-2 p-0 mt-4">
            <Link to="/uploads" element={<Upload />}>
              <Button color="primary" className="medialibraryupload">
                <em className="icon ni ni-upload"></em>
                <span> Upload Video</span>
              </Button>
            </Link>
            <span className="mx-4" style={{ fontSize: "18px" }}>
              or
            </span>
            <div className="input-group m3u8linkhelper">
              <input
                type="text"
                className="form-control "
                placeholder="Video URL (.m3u8 link)"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
                onBlur={handleBlur}
                onKeyUp={handleKeyUp}
              />
              <div className="input-group-prepend">
                <span
                  className={`input-group-text bg-primary text-white pointer ${
                    validationError ? "disabled" : ""
                  } d-flex justify-content-between align-items-center`}
                  id="inputGroup-sizing-lg"
                  style={{ borderRadius: "5px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}
                  onClick={validationError ? null : handleAddVideo}
                >
                  <span>Add Video URL</span>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Button color="primary" id="PopoverBottom" type="button" className="p-0 ms-2 border">
                      <em className="icon ni ni-info-i"></em>
                    </Button>
                    <UncontrolledPopover placement="right" target="PopoverBottom">
                      <PopoverHeader>M3U8 Link</PopoverHeader>
                      <PopoverBody>
                        Get your M3U8 link from <br /> your streaming software
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </span>
              </div>
            </div>
            <KeyboardShortcut />
            {validationError && <div className="text-danger">{validationError}</div>}
          </div>

          <div className="p-0 m-0 mt-4 pt-2">
            <FilterMenu
              searchQuery={searchQuery}
              tagQuery={tagQuery}
              isFilter={isFilter}
              setIsFilter={setIsFilter}
              handleSearchChange={setSearchQuery}
              handleSearchChangeTag={setTagQuery}
              handleSearchChangeTagQuery={handleSearchChangeTagQuery}
              handleSearchChangefirst={handleSearchChangefirst}
              sortingOption={sortingOption}
              SortingOptionMedia={SortingOptionMedia}
              handleSortingChange={handleSortingChange}
              handleMediaTypeChange={handleMediaTypeChange}
              handleMatchedTag={handleMatchedTag}
              selectedMatchedTag={selectedMatchedTag}
              handleMediaTypes={handleMediaTypes}
              clearAllFilters={clearAllFilters}
              className="filter-menu-step"
            />
            <div className="m-0 p-0">
              {videoStreams.length > 0 ? (
                <>
                  <h5>{total_records_count} Videos </h5>
                </>
              ) : (
                <>
                  <h5 className="m-3">Start by uploading videos </h5>
                </>
              )}
              {isLoading ? (
                <>
                  <ShimmerSimpleGallery card imageHeight={160} caption />
                </>
              ) : (
                <InfiniteScroll
                  dataLength={videoStreams.length}
                  next={fetchVideoStreams}
                  scrollThreshold={0.8}
                  hasMore={hasMore}
                  loader={
                    <div className="d-flex justify-content-between align-items-center">
                      <ShimmerThumbnail height={180} width={330} rounded />
                      <ShimmerThumbnail height={180} width={330} rounded />
                      <ShimmerThumbnail height={180} width={330} rounded />
                    </div>
                  }
                  style={{ overflowX: "hidden", overflowY: "hidden" }}
                  endMessage={hasMore && <h6 className="text-center mt-5">No more videos to load.</h6>}
                >
                  <div className="video-channels">
                    <Row>
                      {videoStreams.length > 0 && (
                        <>
                          <Tour
                            steps={tourSteps}
                            isOpen={isTourOpen}
                            accentColor="#e14954"
                            getCurrentStep={(curr) => setCurrentStep(curr)}
                            onRequestClose={() => setIsTourOpen(false)}
                            showDots={false}
                            prevButton={
                              <button
                                className="btn btn-secondary"
                                callbackFunc={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                Prev
                              </button>
                            }
                            nextButton={
                              <button
                                className="btn btn-primary"
                                callbackFunc={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                Next
                              </button>
                            }
                            lastStepNextButton={
                              <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsTourOpen(false);
                                  finishMediaTutorial();
                                  return false;
                                }}
                              >
                                Go To Channels
                              </button>
                            }
                          />
                          {videoStreams.map((videoStream, index) => (
                            <Col xxl={3} lg={3} sm={6} key={index} className="mb-2">
                              <Card
                                className={`card-bordered medialibrary${
                                  index + 1
                                } position-relative product-card shadow-sm pointer ${
                                  highlightedCards.includes(videoStream.id) ? "border-3 border-danger" : "border-0"
                                }`}
                                onClick={(e) => handleCtrlClick(e, videoStream)}
                                onMouseEnter={showKeyboardshorts}
                              >
                                {videoStream.is_scheduled === "1" && (
                                  <Badge
                                    color="primary"
                                    style={{
                                      position: "absolute",
                                      top: 10,
                                      zIndex: 1,
                                      width: "4.5rem",
                                      left: 3,
                                    }}
                                  >
                                    Scheduled
                                  </Badge>
                                )}
                                <div className="product-thumb">
                                  {isLoading ? (
                                    <div
                                      className="position-relative"
                                      style={{
                                        width: "100%",
                                        height: "150px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="d-flex justify-content-start align-items-center">
                                        <Spinner color="primary" size="sm" />
                                        <h5 className="ms-1">Loading...</h5>
                                      </div>
                                    </div>
                                  ) : (
                                    <Link key={videoStream.id} to={`/product-details/${videoStream.id}`}>
                                      <ImageWithFallback
                                        src={videoStream.videothumbnails || defaultImages}
                                        alt="Video Thumbnail"
                                        fallbackSrc={defaultImages}
                                      />
                                    </Link>
                                  )}

                                  <ul className="product-actions">
                                    <li>
                                      <Icon name="eye" onClick={() => toggleDeleteModal(videoStream.id)}></Icon>
                                    </li>
                                    <li>
                                      <Link to={`/edit-video/${videoStream.id}`}>
                                        <TooltipItem
                                          key={"edit" + index}
                                          placement="top"
                                          text="edit"
                                          toolTipList={true}
                                          btntext={[{ name: "edit" }]}
                                          id={"edit" + index}
                                        />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        onClick={() =>
                                          handleTagClick(videoStream.title, videoStream.id, videoStream.tags)
                                        }
                                      >
                                        <TooltipItem
                                          key={"tag" + index}
                                          placement="top"
                                          text="tag"
                                          toolTipList={true}
                                          btntext={[{ name: "tag" }]}
                                          id={"tag" + index}
                                        />
                                      </Link>
                                    </li>
                                    {!videoStream.video_url && (
                                      <li>
                                        <Link id="downloads">
                                          <TooltipItem
                                            key={"download" + index}
                                            placement="top"
                                            text="download"
                                            toolTipList={true}
                                            btntext={[{ name: "download" }]}
                                            id={"download" + index}
                                            onClk={() => handleVideoDownload(videoStream.download_path)}
                                            clicfunction={true}
                                          />
                                        </Link>
                                      </li>
                                    )}

                                    <li>
                                      <Link id="trash">
                                        <TooltipItem
                                          key={"trash" + index}
                                          placement="top"
                                          text="Delete"
                                          toolTipList={true}
                                          btntext={[{ name: "trash" }]}
                                          id={"trash" + index}
                                          onClk={() => handleVideoDelete(videoStream.id)} // Pass a function
                                          clicfunction={true}
                                        />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => handleaddSchedule(videoStream.id, videoStream.channel_uuid)}>
                                        <TooltipItem
                                          key={"tag" + index}
                                          placement="top"
                                          text="Add to scheduler"
                                          toolTipList={true}
                                          btntext={[{ name: "plus" }]}
                                          id={"plus" + index}
                                        />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <div key={index} style={{ cursor: "pointer" }}>
                                  <div className="d-flex justify-content-between p-1 mediaLibraryText">
                                    <p className="mb-0">{videoStream.title}</p>
                                    <p>{formatDuration(videoStream.duration)}</p>
                                  </div>
                                  {selectedVideoStream && selectedVideoStream.videoStreamid === videoStream.id ? (
                                    <div className="p-1">
                                      {tags.map((tag, index) => (
                                        <Badge color="primary" className="me-1" key={index}>
                                          {tag}
                                        </Badge>
                                      ))}
                                    </div>
                                  ) : (
                                    <div className="p-1">
                                      {videoStream.tags &&
                                        videoStream.tags.length > 0 &&
                                        videoStream.tags.map((tag, index) => (
                                          <Badge color="primary" className="me-1" key={index}>
                                            {tag}
                                          </Badge>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              </Card>
                            </Col>
                          ))}
                        </>
                      )}
                    </Row>
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </div>

          <Modal isOpen={isModalOpen1} toggle={() => toggleDeleteModal(null)} className="modal-lg">
            {/* <ModalHeader toggle={() => toggleDeleteModal(null)}>Preview Video</ModalHeader>
            <ModalBody>
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                {loadingVideo ? (
                  <div
                    className="text-center"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "150px",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <Spinner color="primary" size="sm" />
                      <h5 className="ms-1">Loading...</h5>
                    </div>
                  </div>
                ) : null}
                <ReactPlayer
                  url={videoUrls}
                  controls={true}
                  width="100%"
                  height="100%"
                  style={{ position: "absolute", top: 0, left: 0 }}
                  onStart={() => setLoadingVideo(false)}
                  onReady={() => setLoadingVideo(false)}
                />
              </div>
            </ModalBody> */}
            <button type="button" className="mfp-close" onClick={() => toggleDeleteModal(null)}>
              ×
            </button>
            {loadingVideo ? (
              <div
                className="text-center"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "150px",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div className="d-flex justify-content-start align-items-center">
                  <Spinner color="primary" size="sm" />
                  <h5 className="ms-1">Loading...</h5>
                </div>
              </div>
            ) : (
              <MediaPlayer
                title="Media Library"
                src={videoUrls}
                duration={duration}
                autoPlay
                muted
                clipStartTime={0}
                clipEndTime={duration}
                poster={thumbnail}
              >
                <MediaProvider>
                  <Poster className="vds-poster" />
                </MediaProvider>
                <DefaultVideoLayout icons={defaultLayoutIcons} />
              </MediaPlayer>
            )}
            {/* <ModalFooter className="bg-light">
              <Button color="secondary" onClick={toggleDeleteModal}>
                Close
              </Button>
            </ModalFooter> */}
          </Modal>

          <Delete open={isOpen} toggleopen={toggleopen} />
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Tag Video</ModalHeader>
            <ModalBody>
              {selectedVideoStream && (
                <>
                  <pre> {selectedVideoStream.videoStream}</pre>
                  <div className="mb-3 tagsurl">
                    <div className="form-group">
                      <Label htmlFor="default-0" className="form-label">
                        <p>Add tag URL</p>
                      </Label>
                    </div>
                    <TagsInput value={tags} onChange={handleChange} />
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </section>{" "}
    </>
  );
};

export default Video;
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return formattedDuration;
}
