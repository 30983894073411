import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, ModalBody, Row, Tooltip } from "reactstrap";
import { Icon, PreviewCard, ReactDataTable, UserAvatar } from "../../components/Component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/Utils";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import New from "./New";
import { apiRequest } from "../../utils/apiHelper";
import { ShimmerCategoryList, ShimmerThumbnail } from "react-shimmer-effects";
import ShareQrCode from "../QrComponent/ShareQrCode";
import { getConnectedUser, sendMailToConnect } from "../api/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toPng, toSvg } from "html-to-image";
import debounce from "lodash.debounce";
const themeColors = [
  "primary",
  "secondary",
  "warning",
  "danger",
  "success",
  "info",
  "azure",
  "blue",
  "pink",
  "indigo",
  "dark",
  "gray",
  "orange",
  "teal",
  "purple",
];

// Function to get a random color
const getRandomColor = () => {
  return themeColors[Math.floor(Math.random() * themeColors.length)];
};
const Website = () => {
  const [websites, setWebsites] = useState([]);
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchWebsites();
  }, []);

  const fetchWebsites = async () => {
    try {
      const response = await apiRequest("get-website", "POST", null, null);
      if (response.data && response.data.data && Array.isArray(response.data.data.website_data)) {
        setWebsites(response.data.data.website_data);
      } else {
        setWebsites([]);
      }
    } catch (error) {
      console.error("Error fetching websites:", error);
      toast.error("Failed to fetch websites. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteWebsite = async (websiteId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this website!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const authToken = getCookie("authToken", null);
        const formData = new FormData();
        formData.append("website_id", websiteId);

        const response = await apiRequest("delete-website", "POST", formData, "multipart/form-data");
        if (response.data && response.data.type === "success") {
          toast.success(response.data.message);
          setWebsites((prevWebsites) => prevWebsites.filter((website) => website.id !== websiteId));
          setRefreshKey((prevKey) => prevKey + 1);
        } else {
          console.error("Failed to delete website:", response.data.message);
          toast.error("Failed to delete website. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error deleting website:", error);
      toast.error("Failed to delete website. Please try again.");
    }
  };

  const handleCreateWebsite = () => {
    navigate("/websites/new");
  };
  const [modalZoom, setModalZoom] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const openShareModal = (e, link) => {
    setModalZoom(true);
    setShareLink(link);
  };
  const [searchParams, setSearchParams] = useState("");
  const {
    data: shareData,
    isLoading: shareIsLoading,
    isError: shareIsError,
  } = useQuery({
    queryKey: ["shareData", searchParams],
    queryFn: () => getConnectedUser(searchParams),
    enabled: modalZoom,
  });
  console.log(shareData);
  const onSearch = debounce((e) => {
    setSearchParams(e.target.value);
  }, 500);

  const URLCell = ({ row }) => {
    const textRef = useRef(null);

    const copyToClipboard = () => {
      const text = textRef.current;
      navigator.clipboard
        .writeText(text.innerText)
        .then(() => {
          text.focus();
          const range = document.createRange();
          range.selectNodeContents(text);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    };

    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <p
          ref={textRef}
          onClick={() => window.open(row.domain, "_blank")}
          title="Go to website"
          style={{ cursor: "pointer", margin: 0 }}
        >
          {row.domain}
        </p>

        <Icon
          name="copy"
          onClick={copyToClipboard}
          title="Copy to clipboard"
          style={{ cursor: "pointer", marginInline: "30px 20px", fontSize: "1.3rem" }}
        />
      </div>
    );
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title || "",
      sortable: true,
    },
    {
      name: "Channels",
      selector: (row) => row.channels.map((channel) => channel.channel_name).join(", "),
      sortable: true,
    },
    {
      name: "URL",
      cell: (row) => <URLCell row={row} />,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "stretch", gap: "25px", fontSize: "1.1rem" }}>
          <div className="icon-link-container" style={{ cursor: "pointer" }}>
            <Link to={`website-edit/${row.domain_id}`} element={<New />} className="website-edit-icon">
              <Icon name="edit" />
            </Link>
          </div>
          <Icon
            name="trash-fill"
            onClick={() => handleDeleteWebsite(row.id)}
            style={{ cursor: "pointer", color: "#e14954" }}
          />
          <a href={row.domain} target="_blank" title="Go to website" style={{ color: "#008dd2", cursor: "pointer" }}>
            <Icon name="arrow-up-fill-c" />
          </a>
          <span
            target="_blank"
            title="Share"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              openShareModal(e, row.domain);
            }}
          >
            <Icon name="share" />
          </span>
        </div>
      ),
    },
  ];

  const toggleZoom = () => setModalZoom(!modalZoom);
  const [buttonText, setButtonText] = useState("Copy Link");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        setButtonText("Copied");
        setTimeout(() => setButtonText("Copy Link"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const toggles = () => setTooltipOpen(!tooltipOpen);
  const downloadContainerRef = useRef(null);
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], { type: mimeString });
  };
  const handleShare = async () => {
    const container = downloadContainerRef.current;
    if (!container) {
      console.error("Container not found");
      return;
    } else {
      try {
        const pngDataUrl = await toPng(container);
        console.log("PNG URL is", pngDataUrl);

        if (
          navigator.canShare &&
          navigator.canShare({
            files: [new File([], "dummy")],
          })
        ) {
          const blob = await dataURItoBlob(pngDataUrl);
          const file = new File([blob], "image.png", { type: "image/png" });
          await navigator.share({
            title: "Share Image",
            text: "Check out this image!",
            files: [file],
          });
        } else {
          console.warn("Sharing image directly not supported. Sharing link instead.");
          const imageUrl = pngDataUrl;
          await navigator.share({
            title: "Share Image",
            text: "Check out this image! (Link may require saving the image)",
            url: imageUrl,
          });
        }
      } catch (error) {
        console.error("Error sharing:", error.message);
      }
    }
  };
  const [connectionMailIds, setConnectionMailIds] = useState([]);
  console.log(typeof connectionMailIds);

  const handleCheckboxChange = (userId, isChecked) => {
    if (isChecked) {
      setConnectionMailIds((prev) => [...prev, userId]);
    } else {
      setConnectionMailIds((prev) => prev.filter((id) => id !== userId));
    }
  };
  console.log(connectionMailIds);
  useEffect(() => {
    if (!modalZoom) {
      setConnectionMailIds([]);
    }
  }, [modalZoom]);
  const [randomColor, setRandomColor] = useState("");

  useEffect(() => {
    setRandomColor(getRandomColor());
  }, [modalZoom]);
  //send mail button function
  const { mutate: inviteMutation, isPending } = useMutation({
    mutationFn: () => sendMailToConnect(connectionMailIds),
  });
  console.log(isPending);
  const [invitationLoading, setInvitationLoading] = useState(false);
  const sendInvitation = async () => {
    const sendMailPromise = async () => {
      try {
        setInvitationLoading(true);
        const container = downloadContainerRef.current;
        const pngDataUrl = await toPng(container);
        const response = await fetch(`${process.env.REACT_APP_API_URL}send-mail-to-connection`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authToken: getCookie("authToken"),
          },
          body: JSON.stringify({
            user_ids: connectionMailIds,
            image_url: pngDataUrl,
          }),
        });

        const responseData = await response.json();

        if (!responseData.status) {
          throw new Error("Failed to send invitations");
        }

        return responseData;
      } catch (error) {
        setInvitationLoading(false);
        console.log(error);
      } finally {
        setInvitationLoading(false);
      }
    };

    toast.promise(sendMailPromise(), {
      pending: "Sending invitations...",
      success: "Invitations sent successfully!",
      error: "Failed to send invitations",
    });
  };

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <h5>My Network</h5>
          <Button color="primary" onClick={handleCreateWebsite} className="my-2">
            <Icon name="plus" />
            <span>Create Host Network</span>
          </Button>
          <div className="section-color mt-3 p-2">
            {isLoading ? (
              <PreviewCard>
                <ShimmerThumbnail height={40} rounded />
                <ShimmerThumbnail height={40} rounded />
              </PreviewCard>
            ) : (
              <PreviewCard>
                {websites.length > 0 ? (
                  <ReactDataTable data={websites} key={refreshKey} columns={columns} expandableRows pagination />
                ) : (
                  "No websites found. Create a website to explore more."
                )}
              </PreviewCard>
            )}
          </div>
        </div>
      </section>
      {/* share modal */}
      <Modal isOpen={modalZoom} toggle={toggleZoom} modalClassName="zoom" size="xl">
        <ModalBody>
          <Row>
            <Col md="6" className="border-end">
              <div className="share-modal-header">
                <h4 className="fs-2">Share Your Network</h4>
                <p>Yes! You can share your network.</p>
                <div className="text-center">
                  <Button className="btn-primary btn-block w-50" onClick={handleShare}>
                    <Icon name="share" />
                    <span>Share Now!</span>
                  </Button>
                </div>
                <div className="text-center pt-4 pb-3">
                  <h6 className="overline-title overline-title-sap">
                    <span>or</span>
                  </h6>
                </div>
                <ShareQrCode qrText={shareLink} downloadContainerRef={downloadContainerRef} />
              </div>
            </Col>
            <Col md="6">
              <div className="share-modal-header">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="fs-2">Share with connections.</h4>
                    <p>Share with the people you are connected.</p>
                  </div>
                  {connectionMailIds.length > 0 && (
                    <Button className="btn-round" color="primary" onClick={sendInvitation} disabled={invitationLoading}>
                      <Icon name="mail" />
                      <span>Invite Them!</span>
                    </Button>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="form-control-wrap mt-3">
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="email"
                      id="default-0"
                      placeholder="Search connections"
                      onChange={(e) => onSearch(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="connected-user-div">
                {shareIsLoading ? (
                  <>
                    <ShimmerCategoryList title items={1} categoryStyle="STYLE_SEVEN" />
                  </>
                ) : (
                  <div className="card-inner-md ps-0">
                    {shareData?.data.connectionandfollowers?.length > 0 ? (
                      shareData?.data?.connectionandfollowers.map((connection, index) => (
                        <React.Fragment key={index}>
                          <div className="user-card">
                            <UserAvatar text={connection.initials} theme={`${randomColor}-dim`} />
                            <div className="user-info">
                              <span className="lead-text">{connection.username}</span>
                              <span className="sub-text">{connection.silomail}</span>
                            </div>
                            <div className="user-action pe-1">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customCheck${index}`}
                                  onChange={(e) => handleCheckboxChange(connection.id, e.target.checked)}
                                />
                                <label className="custom-control-label" htmlFor={`customCheck${index}`}></label>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </React.Fragment>
                      ))
                    ) : (
                      <ShimmerCategoryList title items={1} categoryStyle="STYLE_SEVEN" />
                    )}
                  </div>
                )}
              </div>
              <div className="align-items-center d-flex mt-1 w-100 border-top" style={{ height: "70px" }}>
                <div className="form-control-wrap w-100">
                  <label className="">or copy link</label>
                  <div className="input-group" style={{ height: "40px" }}>
                    <input type="text" className="form-control" value={shareLink} />
                    <div className="input-group-append">
                      <Button className="bg-danger text-white" id="copym3u8" onClick={handleCopy}>
                        {buttonText}
                      </Button>
                      <Tooltip placement="top" isOpen={tooltipOpen} target="copym3u8" toggle={toggles}>
                        {buttonText === "Copy Link" ? "Copy" : "Copied"}
                      </Tooltip>
                    </div>
                  </div>
                  {/* <span className="form-note">Network Link</span> */}
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Website;
