import React, { useState, useEffect } from "react";
import { BackTo, Icon } from "../../components/Component";
import { useNavigate } from "react-router";
import { Button, Label, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { ColorPicker } from "primereact/colorpicker";
import ChannelModal from "./ChannelModal";
import { toast } from "react-toastify";
import { apiRequest } from "../../utils/apiHelper";
import { useParams } from "react-router-dom";
import { getCookie } from "../../utils/Utils";

import { ShimmerThumbnail } from "react-shimmer-effects";

const New = () => {
  const Navigate = useNavigate("");
  const { id } = useParams();
  const [logo, setlogo] = useState([]);
  const [favicon, setfavicon] = useState([]);
  // const [background, setbackground] = useState("FFFFFF");
  // const [font, setfont] = useState("000000");
  // const [highlight, sethighlight] = useState("999999");
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [title, setTitle] = useState("");
  // const [playbackOptions, setPlaybackOptions] = useState("");
  // const [displayOptions, setDisplayOptions] = useState([]);
  const [footerText, setFooterText] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  // const [header, setHeader] = useState("1");
  // const [pageLayout, setPageLayout] = useState("1");
  const [websiteData, setWebsiteData] = useState(null);
  const [siteLogoBase64, setSiteLogoBase64] = useState("");
  const [siteFaviconBase64, setSiteFaviconBase64] = useState("");
  const [loading, setLoading] = useState(false);

  const [dataLoading, setDataLoading] = useState(true);

  const logochange = (acceptedFiles, set) => {
    set(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  useEffect(() => {
    if (id) {
      const fetchWebsite = async () => {
        try {
          const formData = new FormData();
          formData.append("website_id", id);
          const response = await apiRequest("get-website", "POST", formData, null);
          const websiteData = response.data.data;

          if (websiteData && websiteData.length > 0) {
            const website = websiteData[0];
            const siteLogoBase64FromDB = "data:image/jpeg;base64," + website.site_logo__base64;
            const siteFaviconBase64FromDB = "data:image/jpeg;base64," + website.site_favicon__base64;
            setSiteFaviconBase64(siteFaviconBase64FromDB);
            setSiteLogoBase64(siteLogoBase64FromDB);
            setlogo([{ name: "logo.jpg", preview: siteLogoBase64FromDB }]);
            setfavicon([{ name: "favicon.jpg", preview: siteFaviconBase64FromDB }]);
            // setbackground(website.background_color);
            // setfont(website.font_color);
            // sethighlight(website.highlight_color);
            console.log("websitewebsite", website);
            setSelectedChannels(
              website.channels.map((channel) => ({ id: channel.id, channel_name: channel.channel_name }))
            );
            setTitle(website.title);
            // setPlaybackOptions(website.playback_options);
            // setDisplayOptions(website.display_options);
            setFooterText(website.footer_text);
            setMetaDescription(website.footer_description);
            // setHeader(website.header);
            // setPageLayout(website.page_layout);
          } else {
            toast.error("Website not found.");
            Navigate("/websites");
          }
        } catch (error) {
          console.error("Error fetching website:", error);
          toast.error("Failed to fetch website. Please try again.");
        }
        setDataLoading(false);
      };

      fetchWebsite();
    } else {
      setDataLoading(false);
    }
  }, [id]);

  const faviconchange = (acceptedFiles, set) => {
    set(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  // useEffect(() => {}, [displayOptions, playbackOptions, pageLayout, header]);

  const handleDeleteChannel = (channelId) => {
    setSelectedChannels(selectedChannels.filter((channel) => channel.id !== channelId));
  };

  const [modalopen, setIsOpen] = useState(false);
  const modalopenbox = () => {
    setIsOpen(!modalopen);
  };
  // const handleHeaderChange = (event) => {
  //   setHeader(event.target.id);
  // };
  // const handlewidthChange = (event) => {
  //   setPageLayout(event.target.id === "fixedwidth" ? "1" : "0");
  // };
  const handleMetaDescriptionChange = (event) => {
    setMetaDescription(event.target.value);
  };
  const handleFooterTextChange = (event) => {
    if (event.target.value.length <= 60) {
      setFooterText(event.target.value);
    }
  };
  // const handleCheckboxChange = (event) => {
  //   const checkboxId = event.target.id;
  //   const isChecked = event.target.checked;

  //   if (isChecked) {
  //     setDisplayOptions((prevOptions) => [...prevOptions, checkboxId]);
  //   } else {
  //     setDisplayOptions((prevOptions) => prevOptions.filter((id) => id !== checkboxId));
  //   }
  // };

  // const handleplayCheckboxChange = (event) => {
  //   const checkboxId = event.target.id;
  //   const isChecked = event.target.checked;

  //   if (isChecked) {
  //     setPlaybackOptions((prevOptions) => [...prevOptions, checkboxId]);
  //   } else {
  //     setPlaybackOptions((prevOptions) => prevOptions.filter((id) => id !== checkboxId));
  //   }
  // };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleFormSubmit = async () => {
    if (selectedChannels.length === 0) {
      toast.error("Please select at least one channel.");
      return;
      setLoading(false);
    }
    setLoading(true);
    const customDomain = `Not Set`;
    const formData = new FormData();

    // formData.append("background_color", background);
    // formData.append("font_color", font);
    // formData.append("highlight_color", highlight);
    formData.append("username", getCookie("username", "John"));
    formData.append("website_title", title);
    formData.append("custom_domian", customDomain);
    // formData.append("playback_options", playbackOptions);
    // formData.append("display_options", displayOptions);
    formData.append("footer_text", footerText);
    formData.append("footer_description", metaDescription);
    // formData.append("header", header);
    // formData.append("page_layout", pageLayout);

    let channelNames = selectedChannels.map((channel) => channel.id).join(",");
    formData.append("channels", channelNames);

    try {
      let response;
      if (id) {
        const logoFile = dataURLtoFile(siteLogoBase64, "logo.jpg");
        const faviconFile = dataURLtoFile(siteFaviconBase64, "favicon.jpg");

        if (logo[0] instanceof File) {
          formData.append("site_logo", logo[0]);
        } else {
          formData.append("site_logo", logoFile);
        }

        if (favicon[0] instanceof File) {
          formData.append("site_favicon", favicon[0]);
        } else {
          formData.append("site_favicon", faviconFile);
        }

        formData.append("website_id", id); // Append website_id for update operation
        response = await apiRequest("update-website", "POST", formData, "multipart/form-data");
      } else {
        formData.append("site_logo", logo[0]);
        formData.append("site_favicon", favicon[0]);
        response = await apiRequest("create-website", "POST", formData, "multipart/form-data");
      }
      if (response.data && response.data.type === "success") {
        toast.success(response.data.message);
        Navigate("/websites");
      } else {
        console.error("Failed to create/update website:", response.data.message);
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error creating/updating website:", error);
      toast.error("Failed to create/update website. Please try again.");
    }
  };

  useEffect(() => {
    console.log(logo, favicon);
  }, [logo, favicon]);

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="">
            <div className="flex align-items-center">
              <BackTo link="/websites" className="fs-4 d-grid text-primary mt-sm-1">
                Network
              </BackTo>
              <span className="fs-5 ps-1 text-gray">/ {title}</span>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6">
                {dataLoading ? (
                  <ShimmerThumbnail height={90} rounded />
                ) : (
                  <div className="bg-white p-3 mb-3">
                    <h6>Personalize Your Network</h6>
                    {/* <Button color="primary" onClick={() => modalopenbox()} className="my-2">
                    <Icon name="plus" />
                    <span>Add Channel</span>
                  </Button> */}
                    <Button color="primary" onClick={modalopenbox} className="my-2">
                      <Icon name="plus" />
                      <span>Add Channel</span>
                    </Button>
                    {selectedChannels.length === 0 ? (
                      <p>No channels added yet.</p>
                    ) : (
                      <ul style={{ listStyle: "none", padding: 0 }} className="list-group">
                        {selectedChannels.map((channel) => (
                          <li
                            key={channel.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "8px",
                              justifyContent: "space-between",
                            }}
                            className="list-group-item mb-10"
                          >
                            <span>{channel.channel_name}</span>
                            <button
                              onClick={() => handleDeleteChannel(channel.id)}
                              style={{ marginLeft: "10px" }}
                              className="btn btn-sm btn-danger"
                            >
                              <Icon name="trash-fill" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
                {dataLoading ? (
                  <ShimmerThumbnail height={490} rounded />
                ) : (
                  <div className=" bg-white p-3 mb-3">
                    <h6>Basics</h6>
                    <div className="p-2">
                      <div className="form-group">
                        <Label htmlFor="default-0" className="form-label">
                          <h6 className="fw-semibold">Title</h6>
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Website title"
                            value={title}
                            onChange={(e) => {
                              if (e.target.value.length <= 60) {
                                setTitle(e.target.value);
                              }
                            }}
                            maxLength={60}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <Label htmlFor="default-0" className="form-label">
                          <h6 className="fw-semibold">
                            Custom TV Domain
                            <Button onClick={() => Navigate("/packages")} color="info" size="xs" className="ms-1">
                              Pro
                            </Button>
                          </h6>
                        </Label>
                        <div className="form-control-wrap">
                          <input className="form-control" type="text" placeholder="tv.silocloud.io/watch" disabled />
                        </div>
                      </div>
                      <p>
                        <Link to="">Follow these instructions</Link> to point a custom domain to
                        {` ${process.env.REACT_APP_PROJECT_TITLE}`} Stream Deck. Domains and SSL certificates can take
                        up to 48 hours to take effect.
                      </p>
                      <h6>Design</h6>
                      <div className="row my-3 p-2">
                        <div className="col-lg-6">
                          <span className="fw-bold">Logo</span>
                          <Dropzone
                            onDrop={(acceptedFiles) => logochange(acceptedFiles, setlogo)}
                            accept={[".mp4", ".avi", ".mov"]}
                            maxSize={4194304}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section className="mt-2 text-center">
                                <div {...getRootProps()} className="dropzone upload-zone dz-clickable p-2">
                                  <input {...getInputProps()} />
                                  {logo.length === 0 ? (
                                    <div className="dz-message">
                                      <span className="dz-message-text">Drag and drop file</span>
                                      <span className="dz-message-or">or</span>
                                    </div>
                                  ) : (
                                    <div
                                      key={logo[0].name}
                                      className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                                      style={{ display: "block", textAlign: "center", background: "transparent" }}
                                    >
                                      <img
                                        src={logo[0].preview}
                                        width={100}
                                        height={100}
                                        alt="preview"
                                        style={{ background: "white", objectFit: "contain" }}
                                      />
                                      {/* <div className="dz-image">
                                      </div> */}
                                    </div>
                                  )}
                                  <Button color="primary" className="">
                                    <Icon name="upload" />
                                    <span className="text-white">Add logo</span>
                                  </Button>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <div className="col-lg-6">
                          <span className="fw-bold">Favicon</span>
                          <Dropzone
                            onDrop={(acceptedFiles) => faviconchange(acceptedFiles, setfavicon)}
                            accept={[".jpg", ".png", ".svg"]}
                            maxSize={4194304}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section className="mt-2 text-center">
                                <div {...getRootProps()} className="dropzone upload-zone dz-clickable p-2">
                                  <input {...getInputProps()} />
                                  {favicon.length === 0 ? (
                                    <div className="dz-message">
                                      <span className="dz-message-text">Drag and drop file</span>
                                      <span className="dz-message-or">or</span>
                                    </div>
                                  ) : (
                                    <div
                                      key={favicon[0].name}
                                      className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                                      style={{ display: "block", textAlign: "center", background: "transparent" }}
                                    >
                                      <img
                                        src={favicon[0].preview}
                                        alt="preview"
                                        height={100}
                                        width={100}
                                        style={{ background: "white", objectFit: "contain" }}
                                      />
                                    </div>
                                  )}
                                  <Button color="primary" className="">
                                    <Icon name="upload" />
                                    <span className="text-white">Add Favicon</span>
                                  </Button>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        {/* <div className="mt-3 mt-lg-4">
                        <span className="fw-bold">Header</span>
                        <ul className="custom-control-group g-3 align-center">
                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                defaultChecked={header}
                                id="1"
                                name="header"
                                onChange={handleHeaderChange}
                              />
                              <label className="custom-control-label" htmlFor="1">
                                Show header
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="0"
                                name="header"
                                defaultChecked={header}
                                onChange={handleHeaderChange}
                              />
                              <label className="custom-control-label" htmlFor="0">
                                Hide header
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-3 mt-lg-4">
                        <span className="fw-bold">Page layout</span>
                        <ul className="custom-control-group g-3 align-center">
                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                defaultChecked={pageLayout}
                                id="fixedwidth"
                                name="page-layout"
                                onChange={handlewidthChange}
                              />
                              <label className="custom-control-label" htmlFor="fixedwidth">
                                Fixed width
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="fullwidth"
                                name="page-layout"
                                defaultChecked={pageLayout}
                                onChange={handlewidthChange}
                              />
                              <label className="custom-control-label" htmlFor="fullwidth">
                                Full width
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-3 mt-lg-4">
                        <div className="row">
                          <div className="col-lg-4">
                            <span className="fw-bold">Background color</span>
                            <div className="my-2">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={background}
                                onChange={(e) => setbackground(e.value)}
                                className="mb-1"
                                pt={{
                                  colorHandler: { className: "w-1rem h-1rem" },
                                }}
                              />
                              <span>#{background}</span>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <span className="fw-bold">Font color</span>
                            <div className="my-2">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={font}
                                onChange={(e) => setfont(e.value)}
                                className="mb-1"
                                pt={{
                                  colorHandler: { className: "w-1rem h-1rem" },
                                }}
                              />
                              <span>#{font}</span>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <span className="fw-bold">Highlight color</span>
                            <div className="my-2">
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={highlight}
                                onChange={(e) => sethighlight(e.value)}
                                className="mb-1"
                                pt={{
                                  colorHandler: { className: "w-1rem h-1rem" },
                                }}
                              />
                              <span>#{highlight}</span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                      {/* <h6>Player options</h6>
                    <div className="row my-3">
                      <div className="col-lg-6 ps-4">
                        <div>
                          <span className="fw-bold">Playback options</span>
                          <div className="custom-control mt-2 custom-checkbox custom-control-sm d-block mb-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                              checked={playbackOptions.includes("customCheck1")}
                              onChange={handleplayCheckboxChange}
                            />
                            <label className="custom-control-label" htmlFor="customCheck1">
                              Autoplay (if possible)
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox custom-control-sm d-block mb-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck2"
                              checked={playbackOptions.includes("customCheck2")}
                              onChange={handleplayCheckboxChange}
                            />
                            <label className="custom-control-label" htmlFor="customCheck2">
                              Volume
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 ps-4">
                        <div>
                          <span className="fw-bold">Display options</span>
                          <div className="custom-control mt-2 custom-checkbox custom-control-sm d-block mb-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="showcontrol"
                              checked={displayOptions.includes("showcontrol")}
                              onChange={handleCheckboxChange}
                            />
                            <label className="custom-control-label" htmlFor="showcontrol">
                              Show controls
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox custom-control-sm d-block mb-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="showcontent"
                              checked={displayOptions.includes("showcontent")}
                              onChange={handleCheckboxChange}
                            />
                            <label className="custom-control-label" htmlFor="showcontent">
                              Show content title
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox custom-control-sm d-block mb-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="showshare"
                              checked={displayOptions.includes("showshare")}
                              onChange={handleCheckboxChange}
                            />
                            <label className="custom-control-label" htmlFor="showshare">
                              Show share buttons
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox custom-control-sm d-block mb-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="openplaylist"
                              checked={displayOptions.includes("openplaylist")}
                              onChange={handleCheckboxChange}
                            />
                            <label className="custom-control-label" htmlFor="openplaylist">
                              Open playlist on load
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      <h6>Site information</h6>
                      <div className="p-2">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            <span className="fw-bold">Footer text</span>
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="MyCompany,Inc."
                              value={footerText}
                              onChange={handleFooterTextChange}
                              maxLength={60}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            <span className="fw-bold">Site meta description (for SEO)</span>
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={metaDescription}
                              onChange={handleMetaDescriptionChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button color="primary" onClick={handleFormSubmit} disabled={loading}>
                      {loading ? <Spinner size="sm" color="text-light" class="spinner-border text-light" /> : "Save"}
                    </Button>
                  </div>
                )}
              </div>
              <div className="col-lg-6"></div>
            </div>
            <ChannelModal
              open={modalopen}
              modalopen={modalopenbox}
              selectedChannels={selectedChannels}
              setSelectedChannels={setSelectedChannels}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default New;
