import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { Spinner, Progress, Modal } from "reactstrap";
import { Icon, Button } from "../../components/Component";
import { getCookie } from "../../utils/Utils";
import "../../css/streamdeck_css/streamdeck.css";
import { apiRequest } from "../../utils/apiHelper";
import { toast } from "react-toastify";
import Tour from "reactour";
import uploadvideotutorial from "../../images/StreamDeckTutorial/uploadvideotutorial.png";
import { MediaPlayer, MediaProvider, Poster } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import uploadvideotutorialvideo from "../../images/StreamDeckTutorial/UploadVideoTutorial.webm";
import Cookies from "js-cookie";
const INITIAL_CHUNK_SIZE = 20 * 1024 * 1024;
const MAX_CHUNK_SIZE = 50 * 1024 * 1024;
const GB_THRESHOLD = 1024 * 1024 * 1024;
const MAX_FILE_SIZE = 2 * GB_THRESHOLD;
const MIN_VIDEO_DURATION = 20;

const VideoAdd = ({ onVideoUploadSuccess }) => {
  const navigate = useNavigate();
  const [uploadStatus, setUploadStatus] = useState({});
  const [loading, setLoading] = useState({});
  const [videoDurations, setVideoDurations] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [abortControllers, setAbortControllers] = useState({}); // Track abort controllers for each file
  const [isTourOpen2, setIsTourOpen2] = useState(false);
  const handleVideoUpload = async (files) => {
    const authToken = getCookie("authToken", null);

    const storageDetails = await fetchStorageDetails(authToken);

    if (!storageDetails) {
      toast.error("Failed to fetch storage details. Please try again later.");
      return;
    }

    const { remainingStorage } = storageDetails;

    for (const file of files) {
      console.log(file.size);
      if (file.size > MAX_FILE_SIZE) {
        toast.error(`${file.name} exceeds the 2GB file size limit.`);
        continue;
      }

      // Check if there's enough remaining storage
      if (file.size > remainingStorage) {
        toast.error("Storage limit exceeded, please upgrade your storage plan.");
        continue;
      }

      // Check video duration before uploading
      const duration = await getVideoDuration(file);
      if (duration < MIN_VIDEO_DURATION) {
        toast.error(`${file.name} is shorter than 20 seconds and cannot be uploaded.`);
        continue;
      }

      const controller = new AbortController();
      setAbortControllers((prevControllers) => ({
        ...prevControllers,
        [file.name]: controller,
      }));

      try {
        setLoading((prevLoading) => ({ ...prevLoading, [file.name]: true }));
        setUploadStatus((prevUploadStatus) => ({ ...prevUploadStatus, [file.name]: null }));
        let response;
        let chunkSize = calculateChunkSize(file.size);
        const totalChunks = Math.ceil(file.size / chunkSize);

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
          const start = chunkIndex * chunkSize;
          const end = Math.min(start + chunkSize, file.size);
          const chunk = file.slice(start, end);
          const formData = new FormData();
          formData.append("chunk", chunk);
          formData.append("chunkIndex", chunkIndex);
          formData.append("totalChunks", totalChunks);
          formData.append("fileName", file.name);
          response = await apiRequest("add-video", "POST", formData, "multipart/form-data", {
            signal: controller.signal,
          });
          if (response.status !== 200) {
            throw new Error("Chunk upload failed");
          }

          // Check if the response indicates the video is successfully added
          if (response.data.status === true) {
            setIsTourOpen2(true);
            break; // Stop sending further chunks
          }

          // Update progress
          const progress = Math.floor(((chunkIndex + 1) / totalChunks) * 100);
          setUploadProgress((prevProgress) => ({ ...prevProgress, [file.name]: progress }));
        }

        setVideoDurations((prevDurations) => ({ ...prevDurations, [file.name]: duration }));
        onVideoUploadSuccess(file.name, response.data.data.total_duration);
        setUploadStatus((prevUploadStatus) => ({ ...prevUploadStatus, [file.name]: true }));
      } catch (error) {
        console.error("Error uploading video", error);
        setUploadStatus((prevUploadStatus) => ({ ...prevUploadStatus, [file.name]: false }));
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, [file.name]: false }));
        setAbortControllers((prevControllers) => {
          const updatedControllers = { ...prevControllers };
          delete updatedControllers[file.name];
          return updatedControllers;
        });
      }
    }
  };

  const calculateChunkSize = (fileSize) => {
    let chunkSize = INITIAL_CHUNK_SIZE;
    if (fileSize > GB_THRESHOLD) {
      chunkSize = Math.min(fileSize / 100, MAX_CHUNK_SIZE); // Increase chunk size for files larger than 1GB
    }
    return chunkSize;
  };

  const getVideoDuration = async (videoFile) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onerror = reject;
      video.src = URL.createObjectURL(videoFile);
    });
  };

  const removeUploadedFile = (fileName) => {
    const updatedUploadStatus = { ...uploadStatus };
    delete updatedUploadStatus[fileName];
    setUploadStatus(updatedUploadStatus);
    const updatedProgress = { ...uploadProgress };
    delete updatedProgress[fileName];
    setUploadProgress(updatedProgress);
    const updatedLoading = { ...loading };
    delete updatedLoading[fileName];
    setLoading(updatedLoading);
    const updatedDurations = { ...videoDurations };
    delete updatedDurations[fileName];
    setVideoDurations(updatedDurations);
  };

  const handleSubmit = (files, allFiles) => {
    handleVideoUpload(files.map((f) => f.file));
    allFiles.forEach((f) => f.remove());
  };

  const handleRemove = (file, allFiles) => {
    handleCancelUpload(file.name);
    allFiles.forEach((f) => {
      if (f.file.name === file.name) {
        f.remove();
      }
    });
  };

  const handleCancelUpload = (fileName) => {
    // Remove file from upload status and clear related state
    removeUploadedFile(fileName);
  };

  // const GB_THRESHOLD = 1024 * 1024 * 1024; // 1GB in Bytes

  const fetchStorageDetails = async (authToken) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL_Logo}api/v1/storage/get-user-plan`, {
        method: "GET",
        headers: {
          authToken: authToken,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch storage details");
      }

      const data = await response.json();

      const { storagePlan, usedStorage } = data.data;

      // Convert total storage to bytes
      const totalStorage = convertToBytes(storagePlan.features.storage.value, storagePlan.features.storage.unit);
      console.log("Total storage: " + totalStorage);

      // Convert used storage to bytes
      const usedStorageInBytes = convertToBytes(usedStorage.value, usedStorage.unit);
      console.log("Used storage: " + usedStorageInBytes);

      // Calculate remaining storage
      const remainingStorage = totalStorage - usedStorageInBytes;
      console.log("Remaining storage: " + remainingStorage);

      return {
        remainingStorage: remainingStorage,
      };
    } catch (error) {
      console.error("Error fetching storage details", error);
      return null;
    }
  };

  const convertToBytes = (value, unit) => {
    switch (unit) {
      case "GB":
        return value * GB_THRESHOLD;
      case "MB":
        return value * 1024 * 1024;
      case "KB":
        return value * 1024;
      case "B":
      default:
        return value;
    }
  };
  const [isTourOpen, setIsTourOpen] = useState(true);
  // useEffect(() => {
  //   const tutorialSeen = Cookies.get("uploadTutorial");
  //   if (!tutorialSeen) {
  //     setIsTourOpen(true);
  //     Cookies.set("uploadTutorial", "true", { expires: 7 });
  //   }
  // }, []);

  const tourSteps = [
    {
      selector: ".upload_video_step",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            Upload Your Videos Here
          </p>
          <small>or View Tutorial</small>
          <div className="video-container-tutorial">
            <Button className="btn-round btn-icon border border-4 border-white bg-dark" size="md" onClick={toggleZoom}>
              <Icon name="play" className="text-light" />
            </Button>
            <img src={uploadvideotutorial} alt="Upload video Tutorial" className="border border-2" />
          </div>
        </>
      ),
    },
  ];

  const tourSteps2 = [
    {
      selector: ".uploadedvideos",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            Congrats! Video Is uploaded Now
          </p>
        </>
      ),
    },
  ];
  const [modalZoom, setModalZoom] = useState(false);
  const toggleZoom = () => {
    setModalZoom(!modalZoom);
    setIsTourOpen(false);
  };
  return (
    <>
      <Modal isOpen={modalZoom} toggle={toggleZoom} className="modal-lg">
        <button type="button" className="mfp-close" onClick={toggleZoom}>
          ×
        </button>
        <MediaPlayer title="Video Tutorial" src={uploadvideotutorialvideo} autoPlay muted poster={uploadvideotutorial}>
          <MediaProvider>
            <Poster className="vds-poster" />
          </MediaProvider>
          <DefaultVideoLayout icons={defaultLayoutIcons} />
        </MediaPlayer>
      </Modal>
      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        showDots={false}
        accentColor="#e14954"
        prevButton={
          <button
            className="btn btn-info d-none"
            callbackFunc={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            Prev
          </button>
        }
        nextButton={
          <button
            className="btn btn-primary"
            callbackFunc={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            Next
          </button>
        }
        lastStepNextButton={
          <button
            className="btn btn-primary d-none"
            callbackFunc={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            Upload Now
          </button>
        }
      />
      <Tour
        steps={tourSteps2}
        isOpen={isTourOpen2}
        onRequestClose={() => setIsTourOpen2(false)}
        showDots={false}
        prevButton={
          <button
            className="btn btn-info d-none"
            callbackFunc={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            Prev
          </button>
        }
        nextButton={
          <button
            className="btn btn-primary"
            callbackFunc={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            Next
          </button>
        }
        lastStepNextButton={
          <button
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              navigate("/videos");
              return false;
            }}
          >
            Go To Media Library
          </button>
        }
      />
      <div className="card p-3 pb-0 mt-3 upload_video_step section-color">
        <div className="nk-upload-form mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="pt-1">Upload Videos</h4>
            <Button
              color="primary"
              outline
              className="btn-dim nav-videos"
              size="md"
              onClick={() => navigate("/videos")}
            >
              <em className="icon ni ni-arrow-left"></em>
              <span>Videos</span>
            </Button>
          </div>
          <div className="mb-1 mt-3">
            <Dropzone
              onSubmit={handleSubmit}
              accept="video/*"
              inputContent="Drag and drop video files here or browse"
              multiple
              canRemove
              handleRemove={(file, allFiles) => handleRemove(file, allFiles)}
            />
          </div>
          <div className="limit-word">
            <span className="text-danger">* Max 2GB</span>
          </div>
          <div className="my-3">
            {Object.keys(uploadStatus).map((fileName) => (
              <div
                key={fileName}
                className="d-flex justify-content-between align-items-center my-2 bg-blue-dim p-3 uploadedvideos"
              >
                <div className="d-flex">
                  <div>
                    <p className="mb-0">{fileName}</p>
                    <span>
                      {videoDurations[fileName] ? formatDuration(videoDurations[fileName]) : "Fetching duration..."}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-column">
                  <div className="mb-2 w-100">
                    {loading[fileName] ? (
                      <div className="d-flex align-items-center">
                        <Spinner color="dark" />
                        <Progress
                          value={uploadProgress[fileName] || 0}
                          className="ml-2"
                          style={{ width: "100px", height: "20px" }}
                        >
                          {uploadProgress[fileName]}%
                        </Progress>
                      </div>
                    ) : (
                      <div>
                        {uploadStatus[fileName] ? (
                          <Icon name="check" className="text-success">
                            Ready
                          </Icon>
                        ) : (
                          <Icon name="times" className="text-danger">
                            Error
                          </Icon>
                        )}
                        <Icon
                          name="cross"
                          className="text-secondary ml-2 m-1"
                          onClick={() => removeUploadedFile(fileName)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                  {!loading[fileName] && (
                    <Button color="link" className="link d-flex align-items-center" onClick={() => navigate("/videos")}>
                      Go to Video <Icon name="forward-alt-fill" />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoAdd;

const formatDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);
  return `${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};
