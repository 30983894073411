import React, { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { Spinner } from "reactstrap";

export default function ShareQrCode({ qrText, downloadContainerRef }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="mt-1 text-center" style={{ position: "relative" }} ref={downloadContainerRef}>
        <QRCode
          value={qrText}
          size={180}
          //   logoImage="https://api.silocloud.io/logo/apps/silo-streamdeck.png"
          logoWidth={50}
          style={{ border: "0.5px solid #e0e0e0" }}
          //   enableCORS="true"
        />
        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
      <div className="text-center">
        <small className="text-muted fw-bold">
          Scan this code with your phone to <br /> share your link to social media.
        </small>
      </div>
    </>
  );
}
