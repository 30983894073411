import React, { useEffect } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import AppDropdown from "./dropdown/app/App";
import { Icon } from "../../components/Component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../../css/streamdeck_css/common.css";
import Notification from "./dropdown/notification/Notification.jsx";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import PackageModal from "./PackageModal";
import { useHeaderText } from "../../pages/HeaderTextContext";
import LogoSmall from "../../images/applogos/logowhite.png";
import LogoDark from "../../images/applogos/logodark.png";
import { fetchAPI } from "../../utils/Utils.js";
import Notifications from "./dropdown/notification/Notifications.js";
import SpeechMic from "../../pages/SpeechMic.jsx";

const suggestions = [
  {
    id: 1,
    text: "What is StreamDeck?",
    keys: "streamdeck, platform, streaming, scheduling",
    description: "Learn about StreamDeck, a streaming and scheduling platform for managing media broadcasts.",
    path: "/help/what-is-streamdeck",
  },
  {
    id: 2,
    text: "What is RTMP?",
    keys: "rtmp, protocol, streaming, live, broadcast",
    description: "Learn about RTMP, a protocol used for live broadcasting on StreamDeck.",
    path: "/help/what-is-rtmp",
  },
  {
    id: 3,
    text: "How can I go live?",
    keys: "live, stream, go live, broadcast, RTMP, encoder",
    description: "Steps to go live on StreamDeck using RTMP-compatible encoders.",
    path: "/help/how-can-i-go-live",
  },
  {
    id: 4,
    text: "How to Broadcast a Schedule?",
    keys: "broadcast, schedule, live, stream, program",
    description: "Steps to broadcast a schedule on StreamDeck.",
    path: "/help/how-to-broadcast-a-schedule",
  },
  {
    id: 5,
    text: "What is an M3U8 Link?",
    keys: "m3u8, link, playlist, hls, stream",
    description: "Learn about M3U8, a file format for HTTP Live Streaming used in StreamDeck.",
    path: "/help/what-is-m3u8",
  },
  {
    id: 6,
    text: "Channels",
    keys: "channels, streaming, media, management",
    description: "Overview of channels and how to manage them on StreamDeck.",
    path: "/channels",
  },
  {
    id: 7,
    text: "Create a Channel",
    keys: "create, channel, new channel, stream",
    description: "Guide on creating a new channel on StreamDeck for broadcasting media.",
    path: "/create-channel",
  },
  {
    id: 8,
    text: "Managing Videos",
    keys: "videos, content, library, media",
    description: "Learn how to manage and organize your video content on StreamDeck.",
    path: "/videos",
  },
  {
    id: 9,
    text: "Uploading Videos",
    keys: "uploads, video, media, stream",
    description: "Instructions on how to upload videos to your StreamDeck account for streaming and scheduling.",
    path: "/uploads",
  },
  {
    id: 10,
    text: "Plan a Program",
    keys: "schedule, channel, timeline, broadcast, Scheduling Channel Timeline",
    description:
      "Plan TV programs by adding videos to the schedule, saving the schedule to the server, and starting broadcasts.",
    path: "/schedule-channel/timeline",
  },
  {
    id: 11,
    text: "Live Streams",
    keys: "live streams, create, broadcast, stream",
    description: "Guide to creating live streams on StreamDeck.",
    path: "/live-streams",
  },
  {
    id: 12,
    text: "Previous Live Streams",
    keys: "live records, previous, history, lives",
    description: "Access previous live stream records and broadcasts.",
    path: "/lives",
  },
  {
    id: 13,
    text: "Channel View",
    keys: "channel view, details, media",
    description: "View specific channel details, including media and broadcast history.",
    path: "/channel-view/null",
  },
];

const Header = ({ fixed, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { skin } = useTheme();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchResults, setSearchResults] = useState(suggestions);

  const handleSearchInput = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setShowSuggestions(true);

    // Filter suggestions by matching the query against multiple keys
    const filteredResults = suggestions.filter((suggestion) => {
      return (
        suggestion.text.toLowerCase().includes(query) ||
        suggestion.keys.toLowerCase().includes(query) ||
        suggestion.description.toLowerCase().includes(query)
      );
    });

    setSearchResults(filteredResults);
  };

  const handleSuggestionClick = (suggestion) => {
    // setSearchQuery(suggestion.text);
    setSearchQuery("");
    navigate(`${suggestion.path}`);
    setShowSuggestions(false);
  };

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const [fullScreenOn, setFullScreenOn] = useState(false);
  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  // useEffect(() => {
  //   const fetchTheme = async () => {
  //     try {
  //       const data = new FormData();
  //       data.append("action", "fetch");
  //       const response = await fetchAPI(data, "api/v1/public/frontend-settings");
  //       if (response.status) {
  //         const fetchedTheme = response?.data?.frontend_settings?.theme;
  //         themeUpdate.skin(fetchedTheme === "1" ? "light" : "dark");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchTheme();
  // }, [fullScreenOn]);

  const location = useLocation();
  // const [text, setText] = useState(
  //   "Welcome to our SiloStreamDeck! Explore endless entertainment and discover new favorites. Enjoy your streaming journey with us!"
  // );
  // useEffect(() => {
  //   if (location.pathname === "/videos") {
  //     setText("This is media library you can find all uploaded files here");
  //   }
  //   if (location.pathname === "/lives") {
  //     setText("This is RTMP Streams that you have created all can see here!");
  //   }
  //   if (location.pathname === "/schedule-channel/timeline") {
  //     setText("Schedule your channels using drag and drop in EPG");
  //   }
  // },[location.pathname]);
  const text = useHeaderText();
  useEffect(() => {
    const element = document.querySelector(".sevensegmentdisplay");
    if (element) {
      element.style.animation = "none";
      void element.offsetHeight; // trigger reflow
      element.style.animation = null;
    }
  }, [text]);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${month}/${date}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    // <section className="section-header">
    <div className={`${headerClass} border-0 section-header`}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-lg-none ms-n1">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div>
          <div className="nk-apps-brand ">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              {skin === "dark" ? (
                <img
                  className=""
                  src={LogoDark}
                  alt="logo"
                  style={{ height: "45px", width: "230px", objectFit: "contain" }}
                />
              ) : (
                <img
                  className=""
                  src={LogoSmall}
                  alt="logo-dark"
                  style={{ height: "45px", width: "230px", objectFit: "contain" }}
                />
              )}
            </Link>
          </div>
          <div className="nk-header-app-name pointer">
            <div className="">
              <p className="mb-0 fw-bolder text-blue-color">{time}</p>
              <p className="fs-9px mb-0">{date}</p>
            </div>
            <div>
              <Link to="https://calendar.silocloud.io/">
                <Icon name="calender-date" className="head-icon" />
              </Link>
            </div>
            <div className="form-control-wrap ms-md-4 ms-1 header-search search-block position-relative">
              <div className="form-icon form-icon-left">
                <Icon name="search" />
              </div>
              <input
                className="form-control searchinput"
                type="text"
                id="appsearch"
                placeholder="Search"
                autoFocus
                onChange={handleSearchInput}
                value={searchQuery}
                autoComplete="off"
                // onBlur={() => setSearchQuery("")}
              />
              <div className="form-icon form-icon-right rounded-5">
                <SpeechMic />
              </div>

              {searchQuery && showSuggestions && (
                <div className="suggestion-box">
                  {searchResults.length > 0 ? (
                    <div>
                      <ul>
                        {searchResults.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => handleSuggestionClick(item)}
                            className="d-flex align-items-center"
                          >
                            {/* <div>
                              <img src={item.thumbnail} alt={item.title} style={{ width: "50px", height: "50px" }} />
                            </div> */}
                            <div className="ms-2 d-flex flex-column">
                              <span style={{ whiteSpace: "nowrap" }}>{item.text}</span>
                              {/* <small className="text-muted">
                                <span className="me-2 ">
                                  <img
                                    src={item.profile_image || dp}
                                    alt={item.username}
                                    style={{
                                      height: "15px",
                                      width: "15px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </span>
                                {item.username}
                              </small> */}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div>
                      <ul>
                        <li className="d-flex align-items-center">
                          <p className="m-0">No results for "{searchQuery}"</p>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row w-100 justify-content-center sevensegmentdisplaydiv">
            <div className=" text-information col-lg-11 d-flex align-center">
              <h5 className="sevensegmentdisplay">{text}</h5>
            </div>
          </div>
          <div className="nk-header-tools align-items-center py-1">
            <ul className="nk-quick-nav">
              <li className="mt-1">
                {fullScreenOn ? (
                  <Icon name="minimize" className="pointer fs-4" onClick={toggleFullScreen} />
                ) : (
                  <Icon name="maximize" className="pointer fs-4" onClick={toggleFullScreen} />
                )}
              </li>
              <li className="notification-dropdown">
                <Notifications />
              </li>
              <li className="notification-dropdown">
                <AppDropdown />
              </li>
              <li className="user-dropdown d-flex">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PackageModal toggleIsOpenModal={toggleIsOpen} isOpen={isOpen} />
    </div>
    // </section>
  );
};
export default Header;
