import { createContext } from "@fullcalendar/core/preact";
import Camera from "./CameraLive";
import { titlAddName } from "../app/StremContext";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { BackTo, Icon, Progress } from "../../components/Component";
import Swal from "sweetalert2";
import axios from "axios";
import { apiRequest } from "../../utils/apiHelper";

import {
  Button,
  Card,
  CardBody,
  CardText,
  Tooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Alert,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import HlsPlayer from "./HlsPlayer";
import VideoPlayer from "./VideoPlayer";
import "../../css/streamdeck_css/streamdeck.css";
import { formatSeconds, getCookie } from "../../utils/Utils";
import { fetchAPI } from "../../utils/api_helper";
import { toast } from "react-toastify";
import { StreamAddContext } from "../app/StremContext";
import { StreamContext } from "../StreamContext";
import LiveFeedChannel from "./LiveFeedChannel";
import LiveComponent from "../../components/LiveComponent";
import "../../css/streamdeck_css/streamdeck.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import useStorageUsage from "../hooks/useStorageUsage";
import Tour from "reactour";
import Cookies from "js-cookie";
import { useQuery } from "@tanstack/react-query";
import { getAllFollowers } from "../api/api";
const CreateLiveStream = () => {
  const rtmpurl = useRef(null);
  const navigate = useNavigate();
  const params = useParams();

  const { streamkey, setStreamKey, playback_url_key, setChannelKey, stream_id, setStreamId, setCreateChannel } =
    useContext(StreamAddContext);
  const {
    activestreamkey,
    setActiveStreamKey,
    activeplaybackurl,
    setActiveplaybackurl,
    activestreamkeyPlayback,
    setActivestreamkeyPlayback,
  } = useContext(StreamContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const [playbackurl, setplaybackurl] = useState(false);
  const [streamkurl, setstreamkurl] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [embedCode, setEmbedCode] = useState("");
  const [titleName, settitleName] = useState(params.stream_name);
  const [datalist, setDataList] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [countdown, setCountdown] = useState(0);
  const [userId, setUserId] = useState("");
  const [tempPlayback, setTempPlayback] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [isAuthorizedOBS, setIsAuthorizedOBS] = useState(true);
  const [streamCameraComp, setStreamCameraComp] = useState("");
  const [isCounting, setIsCounting] = useState(false);
  const [manifiest, setVideos] = useState([]);
  const [modalVideo, setModalVideo] = useState(null);
  const [stream, setStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [duration, setDuration] = useState(null);
  const { storageDetails, loading, error } = useStorageUsage();
  const [isstorageFilled, setIsStorageFilled] = useState(false);

  //This is the api to get all users that follow me
  const { data, isError } = useQuery({
    queryKey: ["get-users-follow-me", userId],
    queryFn: getAllFollowers,
  });
  // console.log(data);

  const addtitlename = (e) => {
    let title = e.target.value;
    if (title.length === 0) {
      settitleName("Untitled live stream");
    } else {
      settitleName(title);
    }
  };
  const toggleModal1 = (video, duration) => {
    setModalVideo(video, duration);
    setDuration(duration);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleClick = () => {
    setShowTooltip(true);
  };
  useEffect(() => {
    setCreateChannel(false);
    getStreamData();
    fetchStreamKey();
  }, [userId]);
  useEffect(() => {}, [isPlaying]);
  // useEffect(() => {
  //   fetchVideos();
  // }, [userId]);
  useEffect(() => {
    if (userId && storageDetails.storageFilledAlert !== undefined) {
      setIsStorageFilled(storageDetails.storageFilledAlert);
    }
    fetchVideos();
    // console.log("userId:", userId);
  }, [userId, storageDetails]);
  useEffect(() => {
    // Component did mount logic

    // Return cleanup function
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
          track.enabled = false;
        });
      }
      if (mediaRecorder) {
        mediaRecorder.stop();
        setMediaRecorder(null);
      }
    };
  }, [stream, mediaRecorder]);

  // const fetchVideos = async () => {
  //   try {
  //     if (userId) {
  //       const response = await axios.get(`${process.env.REACT_APP_EXPRESS_URL}manifest-files/${userId}`);
  //       const manifestLinks = response.data.manifest_links;
  //       setVideos(manifestLinks);
  //       // Now you have an array of manifest file links, you can do further processing here
  //       console.log("Manifest file links:", manifestLinks);
  //       toast.success("Manifest files fetched successfully");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching manifest files:", error);
  //   }
  // };

  const fetchVideos = async () => {
    try {
      if (userId) {
        const authToken = getCookie("authToken", null);
        const headers = new Headers();
        headers.append("authToken", authToken);

        const response = await fetch(`${process.env.REACT_APP_LARAVEL_URL}manifest-files/${userId}`, {
          method: "GET",
          headers: headers,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch manifest files");
          setVideos([]);
        }

        const data = await response.json();
        // console.log(data);
        const manifestLinks = data.manifest_links;

        setVideos(data.data.manifest_data);
        toast.success("Manifest files fetched successfully");
      }
    } catch (error) {
      // console.error("Error fetching manifest files:", error);
      // toast.error("Error fetching manifest files");
      setVideos([]);
    }
  };

  const deleteVideo = async (fileName, event) => {
    event.preventDefault();
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    });

    // If user confirms deletion
    // if (confirmed.isConfirmed) {
    //   try {
    //     await axios.delete(`${process.env.REACT_APP_EXPRESS_URL}delete-manifest-file/${userId}/${fileName}`);
    //     console.log("Video deleted successfully");
    //     fetchVideos();
    //     // Refresh the list of videos or perform any other necessary action
    //   } catch (error) {
    //     console.error("Error deleting video:", error);
    //   }
    // }
    if (confirmed.isConfirmed) {
      try {
        const authToken = getCookie("authToken", null); // Assuming you have a getCookie function to retrieve the auth token
        const headers = {
          authToken: authToken,
        };

        await axios.delete(`${process.env.REACT_APP_LARAVEL_URL}delete-manifest-file/${userId}/${fileName}`, {
          headers: headers,
        });
        // console.log("Video deleted successfully");
        fetchVideos();
        // Refresh the list of videos or perform any other necessary action
      } catch (error) {
        console.error("Error deleting video:", error);
      }
    }
  };

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  const [streamingStarted, setStreamingStarted] = useState(false);
  const [showEmptyPlayer, setShowEmptyPlayer] = useState(false);

  // const socket = useRef();
  const getStreamData = async () => {
    let stream_name = params.stream_name;
    let route = "get-live-stream";
    const authToken = getCookie("authToken", null);
    // const authToken=localStorage.getItem("authToken");
    const formDataToSend = new FormData();
    formDataToSend.append("stream_key_id", stream_name);
    const headers = new Headers();
    headers.append("authToken", authToken);

    const response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
      method: "POST",
      headers: headers,
      body: formDataToSend,
    });

    if (!response.ok) {
      throw new Error("Failed to fetch channels from backend");
      return false;
    }
    const data = await response.json();
    setStreamId(data?.data?.livestream?.id);
    setChannelKey(data?.data?.livestream?.playback_url_key);
    setStreamKey(data?.data?.livestream?.stream_key);
    settitleName(data?.data?.livestream?.stream_title);
    setDataList(data?.data?.livestream?.destination_channel);
    setUserId(data?.data?.livestream?.user_id);
    setTempPlayback(data?.data?.playbackStream);
    // console.log("data?.data?.livestream?.stream_key", data?.data?.livestream?.stream_key);
    const destinationId = data?.data?.livestream?.destination_id;
    if (destinationId) {
      const destinationArray = destinationId.split(",");
      // if (data?.data?.livestream?.destination_id !== "") {
      //   const destinationArray = data?.data?.livestream?.destination_id.split(",");
      setSelectedChannels(destinationArray);
    } else {
      setSelectedChannels([]);
    }
  };
  const fetchStreamKey = async () => {
    try {
      // Fetch channels from the backend
      const authToken = getCookie("authToken", null);
      // const authToken=localStorage.getItem("authToken");
      const formDataToSend = new FormData();
      formDataToSend.append("channel_id", 1);
      const headers = new Headers();
      headers.append("authToken", authToken);

      const response = await fetch(`${process.env.REACT_APP_API_URL}get-channel`, {
        method: "POST",
        headers: headers,
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch channels from backend");
      }
      const data = await response.json();
      // Assuming channels is an array and we want to access the stream_key of the first channel

      // const m3u8Exists = await checkM3u8Exists(streamkey);
      // if (m3u8Exists) {
      //   setShowEmptyPlayer(true);
      // }
    } catch (error) {}
  };

  useEffect(() => {}, [streamingStarted]);

  useEffect(() => {}, [isAuthorizedOBS]);

  const handleStartStreamingObs = () => {
    setIsAuthorizedOBS((prevIsAuthorizedOBS) => !prevIsAuthorizedOBS);
  };

  var countdown1;
  var countdownInterval;
  var intervalId;
  useEffect(() => {
    countdown1 = 15;
    if (isCounting) {
      countdownInterval = setInterval(() => {
        if (countdown1 <= 0) {
          clearInterval(countdownInterval);
          setIsCounting(false);
          setIsAuthorizedOBS(false);
          const countdownDisplay = document.getElementById("countdownDisplay");
          if (countdownDisplay) {
            countdownDisplay.innerText = "";
          }
          return;
        }
        countdown1--;
        const countdownDisplay = document.getElementById("countdownDisplay");
        if (countdownDisplay) {
          countdownDisplay.innerText = countdown1;
        }
        console.log("countdown1", countdown1);
      }, 1000);
    }
  }, [isCounting, countdown1]);

  const updateStreamStatus = async (identifierKey, streamStatus = "1") => {
    try {
      const formData = new FormData();
      formData.append("identifier_key", identifierKey);
      formData.append("stream_status", streamStatus);

      const response = await apiRequest("set-stream-status", "POST", formData, "multipart/form-data");
      if (response.data.status) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(`Error updating stream status: ${error.message}`);
    }
  };

  const handleStartStreaming = () => {
    setStreamingStarted(true);
    setIsAuthorized(false);
    setIsLoading(true);
    setIsCounting(true);
    clearInterval(intervalId);
    clearInterval(countdownInterval);
    intervalId = setInterval(async () => {
      await fetchStreamKey();
      const m3u8Exists = await checkM3u8Exists(streamkey);
      if (m3u8Exists) {
        clearInterval(countdownInterval);
        updateStreamStatus(titleName, "1");
        setActiveStreamKey(streamkey);
        setActiveplaybackurl(`${process.env.REACT_APP_STREAM_URL}manifest/${userId}/${playback_url_key}/stream.m3u8`);
        // console.log(`${process.env.REACT_APP_STREAM_URL}manifest/${userId}/${playback_url_key}/stream.m3u8`);
        setActivestreamkeyPlayback(`${process.env.REACT_APP_STREAM_URL}hls/${streamkey}/index.m3u8`);
        updateLiveStreamUrl(`${process.env.REACT_APP_STREAM_URL}hls/${streamkey}/index.m3u8`, "1");
        setShowEmptyPlayer(true);
        setIsPlaying(false);
        setIsLoading(false);
        clearInterval(intervalId);
      }
    }, 5000);
    return () => {
      clearInterval(intervalId);
      clearInterval(countdownInterval);
    };
  };
  const updateLiveStreamUrl = async (live_url, stream_status) => {
    try {
      const formData = new FormData();
      formData.append("live_url", live_url);
      formData.append("stream_name", params.stream_name);
      formData.append("stream_status", stream_status);
      const response = await fetch(`${process.env.REACT_APP_API_URL}update-live-stream-url`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      // console.log(responseData);
    } catch (error) {
      console.error(error);
    }
  };
  // const checkM3u8Exists = async (streamKey1) => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_STREAM_URL}hls/${streamKey1}/index.m3u8`);

  //     return response.ok;
  //   } catch (error) {
  //     console.log(error)
  //     return false;
  //   }
  // };

  let isInitialError = true;

  const checkM3u8Exists = async (streamKey1) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_STREAM_URL}hls/${streamKey1}/index.m3u8`);
      return response.ok;
    } catch (error) {
      // Check if the error is not related to the HTTP request
      if (error.name !== "TypeError" && !isInitialError) {
        console.error("Error occurred while checking m3u8 file existence:", error);
      }
      isInitialError = false;
      return false;
    }
  };

  const updateChannels = async (keyToUpdate, keyType) => {
    try {
      // Send a POST request to update channels
      const authToken = getCookie("authToken", null);
      const formDataToSend = new FormData();
      formDataToSend.append("stream_id", stream_id);

      const headers = new Headers();
      headers.append("authToken", authToken);

      // Append keyToUpdate to the form data
      if (keyType === "playback_url_key") {
        formDataToSend.append("playback_url_key", keyToUpdate);
      } else if (keyType === "stream_key") {
        formDataToSend.append("stream_key", keyToUpdate);
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}update-live-stream`, {
        method: "POST",
        headers: headers,
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error("Failed to update channels");
      }

      var data = await response.json();
      setStreamKey(data.data.livestream.stream_key);
      setChannelKey(data.data.livestream.playback_url_key);
      await fetchStreamKey();
    } catch (error) {
      console.log("Error updating channels:", error);
    }
  };

  const handleEmbedClick = () => {
    const embedCode = `<iframe src="${process.env.REACT_APP_STREAM_URL}hls/${streamkey}/index.m3u8" width="640" height="360" frameborder="0" allowfullscreen></iframe>`;
    setEmbedCode(embedCode);
    setIsModalOpen(true);
  };

  function copycontent() {
    const inputField = document.getElementById("streamkey-input");
    inputField.select();
    document.execCommand("copy");
  }

  function copyrtmcontent() {
    rtmpurl.current.select();
    document.execCommand("copy");
  }

  function copyplayerback() {
    const inputField = document.getElementById("channel_key-input");
    inputField.select();
    document.execCommand("copy");
  }

  const handleUpdateChannelKey = (playback_url_key) => {
    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "This action will update the stream key. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Call updateChannels function with appropriate key
        if (playback_url_key) {
          updateChannels(playback_url_key, "playback_url_key");
        }
        toast.success("playback URL key updated successfully!");
      } else {
        // Handle cancel action if needed
        // For example, show a message to the user
        Swal.fire("Cancelled", "The channel key was not updated.", "info");
      }
    });
  };

  const handleUpdateStreamKey = (streamkey) => {
    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "This action will update the stream key. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Call updateChannels function with appropriate key

        if (streamkey) {
          updateChannels(streamkey, "stream_key");
        }
        toast.success("Stream key updated successfully!");
      } else {
        // Handle cancel action if needed
        // For example, show a message to the user
        Swal.fire("Cancelled", "The stream key was not updated.", "info");
      }
    });
  };

  const [tooltipOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!tooltipOpen);
  };
  const playBackToggle = () => {
    setplaybackurl(!playbackurl);
  };
  const streamToggle = () => {
    setstreamkurl(!streamkurl);
  };

  const handleUpdateStream = async () => {
    const formdata = new FormData();
    formdata.append("stream_title", titleName);
    formdata.append("stream_id", stream_id);

    const getLiveData = await fetchAPI(formdata, "update-live-stream");
    if (getLiveData.status) {
      toast.success(getLiveData.message);
    } else {
      toast.error(getLiveData.message);
    }
  };

  async function deletechannel(channelListId) {
    try {
      const deleteFormdata = new FormData();
      deleteFormdata.append("destination_channel_id", channelListId);
      deleteFormdata.append("live_stream_id", stream_id);
      const deleteData = await fetchAPI(deleteFormdata, "remove-destination-channel");
      if (deleteData.status) {
        toast.success(deleteData.message);
        const updatedChannelList = datalist.filter((channel) => channel.id !== channelListId);
        setDataList(updatedChannelList);
        if (deleteData?.data?.livestream?.destination_id !== "") {
          const destinationArray = deleteData?.data?.livestream?.destination_id.split(",");
          selectedChannels(destinationArray);
        } else {
          selectedChannels([]);
        }
      } else {
        toast.error(deleteData.message);
      }
    } catch (error) {}
  }
  const copyEmbedCode = () => {
    const el = document.createElement("textarea");
    el.value = embedCode;
    el.setAttribute("readonly", ""); // Make textarea read-only to prevent keyboard focus
    el.style.position = "absolute";
    el.style.left = "-9999px"; // Move off-screen
    document.body.appendChild(el);

    el.select();
    document.execCommand("copy");

    document.body.removeChild(el);

    // Highlight the pre code element
    const preCodeEl = document.querySelector("pre");
    if (preCodeEl) {
      preCodeEl.classList.add("highlighted");
      setTimeout(() => {
        preCodeEl.classList.remove("highlighted");
      }, 1000); // Remove the highlight after 1 second
    }

    toast.success("Embed code copied!");
  };
  const [isOpen, setIsOpen] = useState(false);

  const addToMediaLibrary = async (event, folder, filepath, thumbnail_path, duration) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", folder);
      formData.append("filepath", filepath);
      formData.append("thumbnail", thumbnail_path);
      formData.append("duration", duration);
      const response = await fetch(`${process.env.REACT_APP_API_URL}add-to-media-library`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.status) {
        toast.success("Video Added To Media Library Succesfully");
        navigate("/videos");
      } else {
        toast.error("Failed to Add Video To Media Library");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [currentstep, setCurrentStep] = useState();
  const setNotShowAgain = () => {
    Cookies.set("liveTutorial", "true", { expires: 7 });
  };
  useEffect(() => {
    const tutorialSeen = Cookies.get("liveTutorial");
    if (!tutorialSeen) {
      setIsTourOpen(true);
      // Cookies.set("mediaTutorial", "true", { expires: 7 });
    } else {
      setIsTourOpen(false);
    }
  }, []);
  const DoNotShow = () => {
    return (
      <div className="custom-control custom-control-sm custom-checkbox">
        <input type="checkbox" className="custom-control-input" id="customCheck7" onChange={(e) => setNotShowAgain()} />
        <label className="custom-control-label" htmlFor="customCheck7">
          Do not show again.
        </label>
      </div>
    );
  };
  const tourSteps = [
    {
      selector: "",
      content: () => (
        <>
          <h3>👏 Welcome To Live Stream!</h3>
          <p style={{ fontSize: "19px" }} className="m-0">
            This tour guide will help you to start with live streaming <br />
            Click on <strong className="text-primary">Next</strong> button to get started.
          </p>
          <Alert className="" color="danger">
            {/* <Icon name="alert-circle" /> */}
            For Live streaming you require to give permission to <mark className="">Camera</mark> and{" "}
            <mark className="">Microphone</mark>
          </Alert>
          <DoNotShow />
        </>
      ),
    },
    {
      selector: ".livecameradiv",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            This Section will show you camera Play In,
          </p>
          <DoNotShow />
        </>
      ),
    },
    {
      selector: ".rtmpplayoutstep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            This Section will show you RTMP Playout,
          </p>
          <DoNotShow />
        </>
      ),
    },
    {
      selector: ".rtmptutstep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            This Section will provide your Stream URL and Stream Key,
          </p>
          <DoNotShow />
        </>
      ),
      position: "top",
    },
    {
      selector: ".playbackurlstep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            This will be your Playback URL. You can copy and paste it in HLS player.
          </p>
          <DoNotShow />
        </>
      ),
    },
    {
      selector: ".addstreamdeckchannelstep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            Click this button to add channels in live stream.
          </p>
          <DoNotShow />
        </>
      ),
    },
    // {
    //   selector: ".addchannellivesstep",
    //   content: () => (
    //     <>
    //       <p style={{ fontSize: "19px" }} className="m-0">
    //         Select any one channel to add on live and click on Accept.
    //       </p>
    //     </>
    //   ),
    //   scrollDuration: 1500,
    // },
    // {
    //   selector: ".channeltoaddinlive",
    //   content: () => (
    //     <>
    //       <p style={{ fontSize: "19px" }} className="m-0">
    //         Click on update button to update your stream key.
    //       </p>
    //     </>
    //   ),
    // },
  ];
  const [step, setStep] = useState(currentstep);
  const finishChannelTutorial = () => {
    // if (currentstep === 5) {
    setIsOpen(true);
    // }
  };
  const toggleoffcanvas = () => {
    setIsOpen(!isOpen);
  };
  const handleAccept = () => {
    setCurrentStep(7);
    // setStep(6);
    getStreamData();
  };
  // console.log("Current step is" + currentstep);
  // console.log("Go To step is" + step);

  return (
    <>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Embed Code</ModalHeader>
        <ModalBody>
          <pre style={{ overflowX: "hidden", overflowY: "auto", whiteSpace: "pre-wrap" }}>{embedCode}</pre>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={copyEmbedCode}>
            Copy
          </Button>
        </ModalFooter>
      </Modal>
      <titlAddName.Provider value={{ titleName, settitleName }}>
        <section className="m-3">
          {isstorageFilled && (
            <div className="example-alert w-100">
              <Alert className="alert-icon" color="warning">
                <Icon name="alert-circle" />
                Your storage is <strong>90% full</strong>. Please free up some space or{" "}
                <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions?tab=serviceSubscriptions`}>
                  click here
                </Link>{" "}
                upgrade your storage to continue saving your data.
              </Alert>
            </div>
          )}
          <Tour
            steps={tourSteps}
            isOpen={isTourOpen}
            getCurrentStep={(curr) => setCurrentStep(curr)}
            onRequestClose={() => setIsTourOpen(false)}
            showDots={false}
            scrollDuration={1500}
            accentColor="#e14954"
            goToStep={currentstep}
            prevButton={
              <button
                className="btn btn-secondary"
                callbackFunc={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                Prev
              </button>
            }
            nextButton={
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  // openOffCanvas();
                  return false;
                }}
              >
                Next
              </button>
            }
            lastStepNextButton={
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  finishChannelTutorial();
                  return false;
                }}
              >
                Add destination channel
              </button>
            }
          />
          <div className="container-fluid rtmp">
            <div>
              <div className="row my-3">
                <BackTo
                  link="/live-streams"
                  className="fs-5 mb-3 back-live d-grid text-primary"
                  icon="ni ni-video-fill"
                >
                  Live Streams
                  <span className="fs-5 ps-1 text-gray">/ {titleName}</span>
                </BackTo>
                <div className="col-lg-6">
                  <div>
                    <Camera
                      countdown={countdown}
                      isAuthorized={isAuthorized}
                      userId={userId}
                      setCountdown={setCountdown}
                      setIsAuthorized={setIsAuthorized}
                      isAuthorizedOBS={isAuthorizedOBS}
                      setIsAuthorizedOBS={setIsAuthorizedOBS}
                      setIsRecording={setIsRecording}
                      isRecording={isRecording}
                      setIsPlaying={setIsPlaying}
                      IsPlaying={isPlaying}
                      mediaRecorder={mediaRecorder}
                      setMediaRecorder={setMediaRecorder}
                      stream={stream}
                      setStream={setStream}
                    />

                    <div className="p-3 p-xl-4 bg-white mt-3 rtmptutstep">
                      <div className="form-control-wrap mt-3">
                        <label className="form-label fw-semibold">RTMP URL</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            ref={rtmpurl}
                            value={`${process.env.REACT_APP_RTMP_URL}`}
                            readOnly
                          />
                          <div
                            className="input-group-prepend pointer tooltip-container"
                            onClick={() => copyrtmcontent()}
                          >
                            <Button
                              color="dark"
                              className="input-group-text"
                              onClick={() => handleClick()}
                              id="inputGroup-sizing-lg-1"
                            >
                              Copy
                            </Button>
                            {showTooltip && <div className="tooltip-theme">Copied!</div>}
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div className="form-control-wrap mt-3">
                        <label className="form-label fw-semibold">Stream Key</label>
                        <div className="input-group">
                          {streamkey ? (
                            <input
                              type="text"
                              className="form-control"
                              id="streamkey-input"
                              value={streamkey}
                              readOnly
                            />
                          ) : (
                            <div>Loading...</div>
                          )}
                          <div className="input-group-prepend pointer tooltip-container" onClick={() => copycontent()}>
                            <Button
                              color="dark"
                              id="streamkurl"
                              onClick={() => handleUpdateStreamKey(streamkey)}
                              className="input-group-text"
                            >
                              <Icon name="reload" />
                            </Button>
                            <Tooltip placement="top" isOpen={streamkurl} target="streamkurl" toggle={streamToggle}>
                              Reset Stream key
                            </Tooltip>
                            <Button
                              color="dark"
                              className="input-group-text  relative"
                              onClick={() => handleClick()}
                              id="inputGroup-sizing-lg-2"
                            >
                              <Icon name="copy" />
                            </Button>
                            {showTooltip && <div className="tooltip-theme">Copied!</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="mb-3" style={{ lineHeight: "20px" }}>
                        {/* <Button color="light" className="me-1" onClick={handleEmbedClick}>
                          <Icon name="code" />
                          <span>Embed</span>
                        </Button> */}
                        <Button
                          color="primary"
                          className="me-1"
                          onClick={() => {
                            handleStartStreaming();
                          }}
                          disabled={isPlaying || isCounting}
                        >
                          <Icon name="video-fill"></Icon> <span>Go Live</span>
                        </Button>
                      </div>
                      <div>
                        <Button color="primary" className="" onClick={() => navigate("/lives")}>
                          <Icon name="view-list-wd" />
                        </Button>
                      </div>
                    </div>
                    <div>
                      <div className="rtmp rtmpplayoutstep">
                        {!isAuthorized ? (
                          <>
                            {isLoading && (
                              <div className="overlay">
                                <div className="overlayinner p-15">
                                  <h2>Loading...</h2>
                                  <p>Please wait while the stream is loading.</p>
                                  <h2 id="countdownDisplay">{countdown1}</h2>
                                </div>
                              </div>
                            )}
                            {showEmptyPlayer ? (
                              <HlsPlayer
                                key={streamkey}
                                userId={userId}
                                titleName={titleName}
                                src={`${process.env.REACT_APP_STREAM_URL}hls/${streamkey}/index.m3u8`}
                                channelKey={playback_url_key}
                                setIsPlaying={setIsPlaying}
                                poster={require("../../images/Livestreaming/Placeholder.jpg")}
                              />
                            ) : (
                              <div>
                                <HlsPlayer
                                  key={streamkey}
                                  userId={userId}
                                  src="https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                                  poster={require("../../images/Livestreaming/Placeholder.jpg")}
                                  channelKey={playback_url_key}
                                  setIsPlaying={setIsPlaying}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {isAuthorized ? (
                              <div className="cameraLIve">
                                <div className="video-container position-relative">
                                  <video
                                    id="player"
                                    controls
                                    className="m-auto w-100"
                                    autoPlay
                                    muted={true}
                                    poster={require("../../images/Livestreaming/Placeholder.jpg")}
                                  />
                                  <h2 className="countdown" id="countdownid">
                                    {countdown}{" "}
                                  </h2>
                                </div>
                              </div>
                            ) : (
                              <div className="cameraLIve" style={{ display: "none" }}>
                                <div className="video-container position-relative">
                                  <video
                                    id="player"
                                    controls
                                    className="m-auto w-100"
                                    autoPlay
                                    muted={true}
                                    poster={require("../../images/Livestreaming/Placeholder.jpg")}
                                  />
                                  <h2 className="countdown" id="countdownid">
                                    {countdown}{" "}
                                  </h2>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {/* <Card className="card-bordered bg-azure-dim mt-3  text-body" inverse>
                      <CardBody className="card-inner">
                        <CardText>
                          You have 3 minutes stored (among video uploads and live streams) from 300 included in your
                          plan. If you need to store more minutes, please{" "}
                          <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions?tab=packageSubscriptions`}>
                            upgrade your account.
                          </Link>
                        </CardText>
                      </CardBody>
                    </Card> */}
                    <div className="p-3 p-xl-4 bg-white mt-3 mt-xl-4">
                      <h6>Basics</h6>
                      <h6 className="fs-15px mt-3">Title</h6>
                      <div className="input-group ">
                        <input
                          type="text"
                          className="form-control"
                          onChange={addtitlename}
                          placeholder="My Live Stream"
                          value={titleName}
                        />
                        <div className="input-group-prepend pointer">
                          <Button
                            className="pointer input-group-text bg-primary text-white"
                            id="basic-title"
                            onClick={handleUpdateStream}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="p-3 p-xl-4 bg-white mt-3 mt-xl-4 playbackurlstep">
                      <div className="form-control-wrap mt-3">
                        <label className="form-label fw-semibold">Playback URL (m3u8 manifest)</label>

                        <div className="input-group ">
                          {tempPlayback ? (
                            <input
                              type="text"
                              className="form-control"
                              id="channel_key-input"
                              value={tempPlayback}
                              readOnly
                            />
                          ) : (
                            <div>Loading...</div>
                          )}
                          <div
                            className="input-group-prepend pointer tooltip-container"
                            onClick={() => copyplayerback()}
                          >
                            <Button
                              color="dark"
                              id="playbackurl"
                              className="input-group-text"
                              onClick={() => handleUpdateChannelKey(playback_url_key)}
                            >
                              <Icon name="reload" />
                            </Button>
                            <Tooltip placement="top" isOpen={playbackurl} target="playbackurl" toggle={playBackToggle}>
                              Reset Playback URL
                            </Tooltip>
                            <Button
                              color="dark"
                              className="input-group-text"
                              onClick={() => handleClick()}
                              id="inputGroup-sizing-lg-2"
                            >
                              <Icon name="copy" />
                            </Button>
                            {showTooltip && <div className="tooltip-theme">Copied!</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 p-xl-4 bg-white mt-3 mt-xl-4">
                      <h6>Destinations</h6>
                      <div className="d-flex align-items-center mb-3">
                        <Button
                          color="primary"
                          // onClick={() => navigate(`/live-feed-channel/${stream_id}`, { state: { selectedChannels } })}
                          onClick={toggleoffcanvas}
                          className="me-2 addstreamdeckchannelstep"
                        >
                          <Icon name="plus" />
                          <span>Add Streamdeck channel</span>
                        </Button>
                        {/* <div className="custom-control custom-switch custom-toggle custom-toggle-primary">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch2"
                            data-label-off="OFF"
                            data-label-on="ON"
                          />
                          <label className="custom-control-label custom-toggle-slider" htmlFor="customSwitch2"></label>
                        </div> */}
                        <Button color="primary" className="p-0 bg-transparent border-0" id="id">
                          <Icon name="question" color="dark" className="p-1 text-body" />
                        </Button>
                        <Tooltip placement="top" isOpen={tooltipOpen} target="id" toggle={toggle}>
                          If activated, when broadcasting live the signal will be automatically sent to all the channels
                          on the list.
                        </Tooltip>
                      </div>
                      <div>
                        <ul>
                          {datalist?.length > 0 ? (
                            datalist.map((checkbox) => (
                              <li
                                key={checkbox.id}
                                className="border mb-2 p-2 d-flex align-items-center justify-content-between pointer shadow-sm channeltoaddinlive"
                              >
                                <div>
                                  <span className="">
                                    {checkbox.channel_type === "0" ? (
                                      checkbox.linear_channel_type === "0" ? (
                                        <Icon name="calendar" className="fs-4 text-warning" />
                                      ) : (
                                        <Icon name="redo" className="fs-4 text-warning" />
                                      )
                                    ) : checkbox.channel_type === "1" ? (
                                      <Icon name="spark" className="fs-4 text-success" />
                                    ) : checkbox.channel_type === "2" ? (
                                      <Icon name="view-list-wd" className="fs-4 text-primary" />
                                    ) : (
                                      <Icon name="redo" className="fs-4 text-warning" />
                                    )}
                                  </span>
                                  <span className="ps-1">{checkbox.channel_name}</span>
                                </div>
                                <span className="" onClick={() => deletechannel(checkbox.id)}>
                                  <Icon name="trash"></Icon>
                                </span>
                              </li>
                            ))
                          ) : (
                            <p className="">No channels added yet.</p>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="p-3 p-xl-4 bg-white mt-3 mt-xl-4 cameralivefetchvideos">
                      <h6>Latest Live Streams</h6>
                      {manifiest.length > 0 ? (
                        <div className="row">
                          {manifiest.map((video, index) => (
                            <div key={index} className="col-sm-6 col-lg-4 col-xxl-3 pointer">
                              <Card
                                className="card-bordered d-flex align-items-center border-0 position-relative"
                                style={{
                                  backgroundImage: `url(${video.thumbnail})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "211px 160px",
                                }}
                              >
                                {/* <div className="cameralivefetchvideosbadge">
                                  <Badge color="secondary">{video.date}</Badge>
                                </div> */}
                                <div
                                  className="cameralivefetchvideosbadgeduration fw-bold text-dark"
                                  style={{ zIndex: 9 }}
                                >
                                  <Badge color="black border-0">{formatSeconds(video.duration)}</Badge>
                                </div>
                                <div className="cameralivefetchvideosdelete">
                                  <UncontrolledDropdown>
                                    <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
                                      <Icon name="more-v"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <ul className="link-list-opt">
                                        <li>
                                          <DropdownItem tag="a" href="#links" onClick={(ev) => ev.preventDefault()}>
                                            <Icon name="share"></Icon>
                                            <span>Share Stream</span>
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#links"
                                            onClick={(ev) => deleteVideo(getFileNameFromUrl(video.manifest_link), ev)}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Delete</span>
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#links"
                                            onClick={(ev) =>
                                              addToMediaLibrary(
                                                ev,
                                                video.filename,
                                                video.outputFile,
                                                video.thumbnailFile,
                                                video.duration
                                              )
                                            }
                                          >
                                            <Icon name="plus"></Icon>
                                            <span>Add To Media Library</span>
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="product-thumb">
                                  <ReactPlayer
                                    url={`${video.manifest_link}`}
                                    muted={true}
                                    width="100%"
                                    height="180px"
                                    // poster={video.thumbnail}
                                  />
                                  <ul className="product-actions">
                                    <Button
                                      className="btn-round btn-icon cameralivefetchvideosbutton"
                                      size="lg"
                                      onClick={() => toggleModal1(video.manifest_link, video.duration)}
                                    >
                                      <Icon name="play-fill" />
                                    </Button>
                                  </ul>
                                </div>
                              </Card>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="">No live streams recorded yet.</p>
                      )}

                      <Modal size="lg" isOpen={modalVideo !== null} toggle={() => setModalVideo(null)}>
                        <button type="button" className="mfp-close" onClick={() => setModalVideo(null)}>
                          ×
                        </button>
                        {modalVideo ? (
                          // <MediaPlayer title="StreamDeck" src={modalVideo} autoPlay="true" muted duration={duration}>
                          //   <MediaProvider />
                          //   <DefaultVideoLayout icons={defaultLayoutIcons} />
                          // </MediaPlayer>
                          <ReactPlayer
                            url={`${modalVideo}`}
                            muted={true}
                            autoPlay={true}
                            controls={true}
                            width="100%"
                            // height="180px"
                            // poster={video.thumbnail}
                          />
                        ) : (
                          ""
                        )}
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Offcanvas direction="end" isOpen={isOpen} toggle={toggleoffcanvas}>
          <OffcanvasHeader toggle={toggleoffcanvas}></OffcanvasHeader>
          <LiveFeedChannel stream_id={stream_id} toggleOffcanvas={toggleoffcanvas} onAccept={handleAccept} />
        </Offcanvas>
      </titlAddName.Provider>
    </>
  );
};
const getFileNameFromUrl = (videoUrl) => {
  const parts = videoUrl.split("/");
  const fileName = parts[parts.length - 2] + "/" + parts[parts.length - 1];
  return fileName;
};

export default CreateLiveStream;
