import React, { useEffect, useState } from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Collapse, CarouselCaption } from "reactstrap";
import sdAi from "../../assets/images/streamDeckAI.webp";
import schedule from "../../assets/images/schedule.png";
import programType from "../../assets/images/vdoType2.png";
import live from "../../assets/images/live.png";
import { useParams } from "react-router";

const HelpAccordion = ({ className, variation }) => {
  const { key } = useParams();
  const [isOpen, setIsOpen] = useState("1");

  const toggleCollapse = (param, path) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
      window.history.pushState(null, "", `/help/${path}`);
    }
  };

  // Array of objects for accordion content
  const accordionData = [
    {
      id: "1",
      title: "What is StreamDeck?",
      path: "what-is-streamdeck",
      content: `StreamDeck is a streaming and scheduling platform that allows users to access, stream, and manage various forms of content. 
        It is designed to support users in creating and scheduling live streams or other media broadcasts.
        The platform helps streamline the process of managing content by offering scheduling tools and access to an array of streaming options.
      `,
    },
    {
      id: "2",
      title: "What is RTMP?",
      path: "what-is-rtmp",
      content: `RTMP (Real-Time Messaging Protocol) is a streaming protocol for transmitting audio, video, and data over the internet.
        StreamDeck uses it to facilitate low-latency streaming between an encoder and media server. RTMP is often employed in live broadcasting and supports a smooth streaming experience.
      `,
    },
    {
      id: "3",
      title: "How can I go live?",
      path: "how-can-i-go-live",
      content: `To go live on StreamDeck, follow these steps:
        
        1. Create a Channel: Start by creating a channel on your StreamDeck account.
        2. Access Live Streams: Navigate to the /live-streams section on the platform.
        3. Broadcast Live: Use any RTMP-compatible hardware or software encoder, such as OBS, Wirecast, or vMix, to start broadcasting.
        4. Select Destination Channel: Choose the channel you want to go live on to begin your broadcast.
      `,
    },
    {
      id: "4",
      title: "How to Broadcast a Schedule?",
      path: "how-to-broadcast-a-schedule",
      content: `To broadcast a schedule on StreamDeck, follow these steps:
        
        1. Choose a Channel: Select a channel from your account.
        2. Upload Videos: Add your videos through the upload interface.
        3. Schedule Videos: Select and arrange the videos in the scheduler.
        4. Save Schedule: Save your schedule to the server.
        5. Start Broadcasting: Click the broadcast button to go live.
      `,
    },
    {
      id: "5",
      title: "What is an M3U8 Link?",
      path: "what-is-m3u8",
      content: `M3U8 is a playlist file format used in HTTP Live Streaming (HLS) protocols to stream audio and video content. 
        StreamDeck supports M3U8 links, which are often used to stream live or on-demand media over the internet by breaking it into smaller segments.
        These links are crucial for adaptive bitrate streaming, ensuring a smooth experience across different network conditions.
      `,
    },
  ];

  useEffect(() => {
    const matchedItem = accordionData.find((item) => item.path === key); // Match based on the path property

    if (matchedItem) {
      setIsOpen(matchedItem.id); // Open the matching accordion item
    } else {
      setIsOpen("1"); // Default to the first item or any other default behavior
    }
  }, [key]);

  return (
    <>
      <div className="row justify-content-center faq-section w-100">
        <div className="col-md-5">
          <div className={`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`}>
            {accordionData.map((item) => (
              <div className="accordion-item" key={item.id}>
                <div
                  className={`accordion-head${isOpen !== item.id ? " collapsed" : ""}`}
                  onClick={() => toggleCollapse(item.id, item.path)} // Pass the path to toggleCollapse
                >
                  <h6 className="title">{item.title}</h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === item.id}>
                  <div className="accordion-inner">
                    <p>{item.content}</p>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-5">
          <div className="mb-2">
            <BasicCarousel />
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpAccordion;

const items = [
  {
    src: sdAi,
    altText: "Slide 1",
    captionHead: "Streaming & Scheduling Platform",
    captionText: "StreamDeck helps users easily manage and schedule live streams.",
  },
  {
    src: programType,
    altText: "Slide 6",
    captionHead: "Types of Content",
    captionText: "StreamDeck supports Shorts, Movies, Informal videos, and Commercials.",
  },
  {
    src: live,
    altText: "Slide 2",
    captionHead: "RTMP Integration",
    captionText: "Low-latency streaming via RTMP ensures smooth live broadcasts.",
  },
  {
    src: schedule,
    altText: "Slide 4",
    captionHead: "Content Scheduling",
    captionText: "Arrange videos on StreamDeck’s program for seamless broadcasting.",
  },
];

const BasicCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="text-white"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption captionText={item.captionText} captionHeader={item.captionHead} />
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={3000}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};
