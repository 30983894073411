import { useState, useCallback, useMemo, useEffect, useContext, useRef } from "react";
import { useEpg } from "@nessprim/planby-pro";
import { ELEMENTS } from "./helpers";
import { theme, theme2 } from "./helpers/theme";
import { useResources } from "./hooks/useResources"; // Import the useResources hook
import { getCookie } from "../../utils/Utils";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import ScheduledChannelContext from "../ScheduledChannelContext";
import { useTheme } from "../../layout/provider/Theme";

export function useApp(
  selecteddate,
  deletes,
  setDeletes,
  swap,
  setswap,
  zoomcout,
  editedData,
  setEditedData,
  // reset,
  // setReset,
  saveLoading,
  planEpg,
  newSince,
  setNewSince,
  setRecentVideo,
  swapEdit,
  setSavedEpg,
  epgRef
) {
  const [elements, setElements] = useState(ELEMENTS);
  const [epg, setEpg] = useState([]);
  const [epgApi, setEpgApi] = useState([]);
  const { selectedId, updateSchedulerData, schedulerData, resetSchedulerData } = useContext(ScheduledChannelContext);
  const { channels } = useResources(selectedId);
  const [isEpg, setIsEpg] = useState(false);
  const channelsData = useMemo(() => channels, [channels]);
  const epgData = useMemo(() => epg, [epg]);

  const { skin } = useTheme();

  // console.log(epgData);

  // API call to add epg data
  // const setPrograms = useCallback(async () => {
  //   const promise = fetch(`${process.env.REACT_APP_API_URL}set-programs-channels`, {
  //     method: "POST",
  //     headers: {
  //       authToken: getCookie("authToken", null),
  //     },
  //     body: JSON.stringify(epgApi),
  //   }).then(async (response) => {
  //     const responseData = await response.json();
  //     if (response.status) {
  //       return responseData;
  //     } else {
  //       throw new Error("Failed to add program schedule");
  //     }
  //   });

  //   toast.promise(promise, {
  //     pending: "Adding program schedule...",
  //     success: "Program schedule added successfully",
  //     error: "Failed to add program schedule",
  //   });

  //   try {
  //     await promise;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [epgApi]);
  // const debouncedSetPrograms = useMemo(() => debounce(setPrograms, 500), [setPrograms]);

  // useEffect(() => {
  //   if (epgApi.length > 0) {
  //     debouncedSetPrograms();
  //   }
  //   return () => {
  //     debouncedSetPrograms.cancel();
  //   };
  // }, [epgApi, debouncedSetPrograms]);

  useEffect(() => {
    // console.log("swapped", swapEdit);
    setEpg(swapEdit);
  }, [swapEdit]);

  const fetchEpgData = async () => {
    try {
      setIsEpg(false);
      const formData = new FormData();
      formData.append("channel_id", selectedId);
      const response = await fetch(`${process.env.REACT_APP_API_URL}get-programs-channels`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.status) {
        // console.log("Hooks EPG", JSON.parse(responseData.data));
        // setEpg(JSON.parse(responseData.data));
        const epgJson = JSON.parse(responseData.data);
        console.log(epgJson);
        if (epgJson && epgJson.length > 0) {
          const today = new Date().toISOString().split("T")[0];
          const filteredSet = epgJson.filter((item) => item.date === today);
          console.log(epgJson, filteredSet, today);
          // const sortedSet = filteredSet.sort((a, b) => new Date(a.since) - new Date(b.since));
          setEpg(filteredSet);
          // console.log(typeof selectedId);
        }
        setSavedEpg(JSON.parse(responseData.data));
        setIsEpg(true);
        // setData(JSON.parse(responseData.data));
      } else {
        setIsEpg(true);
      }
    } catch (error) {
      console.error(error);
      setEpg(true);
    }
  };
  const debouncedFetchEpgData = useMemo(() => debounce(fetchEpgData, 500), [selectedId]);

  useEffect(() => {
    debouncedFetchEpgData();
    setDeletes(false);
    setswap(false);
    setEditedData(false);
    setEpg([]);
    setEpgApi([]);
    updateSchedulerData([]);
    return () => {
      debouncedFetchEpgData.cancel();
    };
  }, [selectedId, saveLoading, deletes]);

  useEffect(() => {
    debouncedFetchEpgData();
    setDeletes(false);
    setswap(false);
    setEditedData(false);
    return () => {
      debouncedFetchEpgData.cancel();
    };
  }, [selectedId, deletes, editedData, saveLoading]);
  // useEffect(() => {
  //   setEpg([]);
  //   setEpgApi([]);
  //   // setReset(false);
  // }, [reset, setReset]);

  // let sinceTime;
  const [sinceTime, setsinceTime] = useState("");
  const [isDnd, setIsDnd] = useState(true);
  const checkIfScheduled = async () => {
    const formData = new FormData();
    formData.append("channel_id", selectedId);
    formData.append("date", selecteddate);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}is-scheduled`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      setIsDnd(responseData.data.isScheduled);
      // console.log(responseData);
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedCheckIfScheduled = debounce(checkIfScheduled, 800);

  useEffect(() => {
    debouncedCheckIfScheduled();
    return () => {
      debouncedCheckIfScheduled.cancel();
    };
  }, [selectedId]);
  useEffect(() => {
    const adjustVideoSchedule = (videoData) => {
      const wasArray = Array.isArray(videoData);
      if (!wasArray) {
        videoData = videoData;
      }

      const today = new Date().toISOString().split("T")[0];
      const formatDate = (date) => {
        const pad = (num) => (num < 10 ? "0" : "") + num;
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(
          date.getMinutes()
        )}:${pad(date.getSeconds())}`;
      };

      let filteredVideoData = videoData.filter((video) => video.date === today);

      if (filteredVideoData.length === 0) {
        // console.log(wasArray ? videoData : videoData[0]);
        return wasArray ? videoData : videoData[0];
      }

      let endTime = new Date(filteredVideoData[0].till);
      filteredVideoData[0].since = formatDate(new Date(filteredVideoData[0].since));
      filteredVideoData[0].till = formatDate(endTime);

      for (let i = 1; i < filteredVideoData.length; i++) {
        let startTime = new Date(endTime);
        startTime.setMinutes(startTime.getMinutes() + 1);
        let endTimeNew = new Date(startTime);
        endTimeNew.setSeconds(endTimeNew.getSeconds() + parseInt(filteredVideoData[i].duration));

        filteredVideoData[i].since = formatDate(startTime);
        filteredVideoData[i].till = formatDate(endTimeNew);
        endTime = endTimeNew;
      }

      let resultVideoData = videoData.map((video) => {
        if (video.date === today) {
          return filteredVideoData.shift();
        }
        return video;
      });

      // console.log(wasArray ? resultVideoData : resultVideoData[0]);
      // setEpg((prev) => [...prev, resultVideoData]);
      return wasArray ? resultVideoData : resultVideoData[0];
    };
    // adjustVideoSchedule(schedulerData);
  }, [schedulerData]);

  useEffect(() => {
    if (planEpg && planEpg?.length > 0) {
      const newArray = [...planEpg];
      newArray.sort((a, b) => new Date(a.till) - new Date(b.till));
      const latestTillTime = newArray[newArray.length - 1]?.till;

      const tillDate = new Date(latestTillTime);
      // Add 1 second to the Date object
      tillDate.setSeconds(tillDate.getSeconds() + 1);

      sessionStorage.setItem("newSinceTime", formatDateTime(tillDate));
      // console.log("sess", sessionStorage.getItem("newSinceTime"));
      setNewSince(sessionStorage.getItem("newSinceTime"));
    } else {
      sessionStorage.removeItem("newSinceTime");
    }
  }, [planEpg, epgApi, epg]);
  // console.log(zoomcout);

  const { getEpgProps, getLayoutProps, getDropItemData, onScrollToNow, scrollX } = useEpg({
    channels: channelsData,
    epg: epgData ? epgData : [],
    startDate: selecteddate + "T00:00:00",
    endDate: selecteddate + "T23:59:00",
    // isResize: true,
    isLine: true,
    height: "auto",
    // isBaseTimeFormat: true,
    isInitialScrollToNow: true,
    timelineHeight: 100,
    // timelineDividers: 40,
    dayWidth: zoomcout,

    liveRefreshTime: 120,
    grid: {
      enabled: true,
      onGridItemDrop: (props) => {
        const newElement = getDropItemData(props);

        setRecentVideo(newElement.playback_path);

        const newSinceTime = sessionStorage.getItem("newSinceTime");
        // console.log("drop at", newSinceTime);

        if (newSinceTime) {
          newElement.since = newSinceTime;
        }

        const sinceDate = new Date(newElement.since);
        const durationInSeconds = parseInt(newElement.duration, 10);
        if (durationInSeconds < 20) {
          // console.log(durationInSeconds);
          toast.error("You can't upload programs that are less than 20 seconds");
          return;
        }
        const tillDate = new Date(sinceDate.getTime() + durationInSeconds * 1000);
        const formatDateTime = (date) => {
          const pad = (num) => (num < 10 ? "0" + num : num);
          return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(
            date.getHours()
          )}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
        };

        newElement.till = formatDateTime(tillDate);
        setsinceTime(newElement.since);
        // const adujutedEpg = adjustVideoSchedule(newElement);
        setEpg((prev) => [...prev, newElement]);
        setEpgApi((prev) => [...prev, newElement]);
        updateSchedulerData([newElement]);
      },
      hoverHighlight: false,
    },
    dnd: {
      enabled: true,
      mode: "multi-rows",
      onDnDMouseUp: (event) => {
        // console.log(event);
        // if (event.data.index !== 0) {
        //   console.log("prevented ");

        //   return false;
        //   // event.preventDefault();
        // }
        return true;
      },
      onDnDSuccess: (event) => {
        // console.log("onDnDSuccess", event);
        setEpgApi((prev) => [...prev, event]);
        setEpg((prev) => resolveOverlaps(prev));
        setEpg((prev) => removeGaps(prev));
        // setEpg((prev) => {
        //   const updatedEpg = [...prev];
        //   const firstProgram = updatedEpg[0];

        //   // Decrease the 'since' time of the first program by 1 minute
        //   const originalSince = firstProgram.since;
        //   const adjustedSince = new Date(new Date(originalSince).getTime() - 60000);
        //   firstProgram.since = formatDateTime(adjustedSince);

        //   // Revert the 'since' time back to the original after 100ms
        //   setTimeout(() => {
        //     setEpg((currentEpg) => {
        //       const revertedEpg = [...currentEpg];
        //       revertedEpg[0].since = originalSince;
        //       return revertedEpg;
        //     });
        //   }, 100);

        //   return updatedEpg;
        // });
        setRecentVideo(event.playback_path);
      },
    },
    overlap: {
      enabled: true,
      mode: "layer",
      layerOverlapLevel: 0.6,
    },
    // timezone: {
    //   enabled: true,
    //   mode: "utc",
    //   zone: "UTC",
    // },
    // theme,
    ...(skin === "light" ? { theme: theme2 } : { theme: theme }),
  });

  function fadeOut(element, duration = 500) {
    element.style.transition = `opacity ${duration}ms ease-in-out`;
    element.style.opacity = 0.7;
  }

  function fadeIn(element, duration = 500) {
    element.style.transition = `opacity ${duration}ms ease-in-out`;
    element.style.opacity = 1;
  }

  const [scrollCenterPercentage, setScrollCenterPercentage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      onScrollToNow();
    }, 500);
  }, []);

  useEffect(() => {
    // Save scroll percentage whenever the scrollX changes
    handleScroll();
  }, [scrollX]);

  useEffect(() => {
    const element = epgRef.current?.querySelector("#planby-layout-scrollbox");
    if (!element) return;

    const elementInner = element.querySelector(".planby-content");

    // Disable smooth scrolling temporarily
    fadeOut(elementInner, 100);
    element.style.scrollBehavior = "auto";

    // Adjust the scroll position over the duration of the width transition

    // Re-enable smooth scrolling after the width transition ends
    const timeoutId = setTimeout(() => {
      adjustScrollOnResize(element);
      fadeIn(elementInner, 500);
      element.style.scrollBehavior = "smooth";
    }, 10); // Same as your CSS transition duration

    return () => clearTimeout(timeoutId);
  }, [zoomcout]);

  // // Save the scroll percentage based on the current scroll position
  function handleScroll() {
    const element = epgRef.current?.querySelector("#planby-layout-scrollbox");
    if (!element) return;

    const visibleWidth = element.clientWidth;
    const currentScroll = element.scrollLeft;
    const centerPosition = currentScroll + visibleWidth / 2.17;
    const totalWidth = element.scrollWidth;

    const centerPercentage = centerPosition / totalWidth;
    setScrollCenterPercentage(centerPercentage);
    // console.log("Scroll percentage saved:", centerPercentage, totalWidth);
  }

  // Adjust the scroll position to maintain the center based on the new width
  function adjustScrollOnResize() {
    const element = epgRef.current?.querySelector("#planby-layout-scrollbox");
    if (!element) return;

    const visibleWidth = element.clientWidth;
    const newTotalWidth = element.scrollWidth;
    const newCenterPosition = scrollCenterPercentage * newTotalWidth;

    const newScrollPosition = newCenterPosition - visibleWidth / 2.17;
    element.scrollLeft = newScrollPosition;

    // console.log("Scroll adjusted to:", newScrollPosition, scrollCenterPercentage, newTotalWidth);
  }

  const handleRemoveElement = useCallback(
    (id) => {
      const newElements = elements.filter((element) => element.id !== id);
      setElements(newElements);
    },
    [elements]
  );

  return {
    isLoading: !isEpg,
    elements,
    getEpgProps,
    getLayoutProps,
    removeElement: handleRemoveElement,
    sinceTime: sinceTime,
    // epgJson: resolveOverlaps(epg, setEpg, setEpgApi),
    epgJson: epg,
    onScrollToNow,
  };
}

function resolveOverlaps(epgData) {
  // Sort the array by 'since' time to ensure proper order
  epgData.sort((a, b) => new Date(a.since) - new Date(b.since));

  for (let i = 1; i < epgData.length; i++) {
    const prev = epgData[i - 1];
    const curr = epgData[i];

    // If the 'since' time of the current element is before the 'till' time of the previous element
    if (new Date(curr.since) < new Date(prev.till)) {
      // Calculate the time difference between the original and adjusted 'since' time
      const timeDifference = new Date(prev.till) - new Date(curr.since);
      // console.log(timeDifference);

      // Adjust the 'since' and 'till' times of the current element
      const newSince = new Date(new Date(prev.till).getTime() + 1000);
      curr.since = formatDateTime(newSince);
      // curr.since = prev.till;
      // curr.till = new Date(new Date(curr.till).getTime() + timeDifference).toISOString();
      let till = new Date(new Date(curr.till).getTime() + timeDifference + 1000);
      curr.till = formatDateTime(till);

      // Adjust the 'since' and 'till' times of all subsequent elements
      for (let j = i + 1; j < epgData.length; j++) {
        let xsince = new Date(new Date(epgData[j].since).getTime() + timeDifference + 1000);
        epgData[j].since = formatDateTime(xsince);
        let xtill = new Date(new Date(epgData[j].till).getTime() + timeDifference + 1000);
        epgData[j].till = formatDateTime(xtill);
      }
    }
  }
  // console.log(epgData);
  // return removeGaps(epgData);
  return epgData;
}

function removeGaps(epgData) {
  if (epgData.length === 0) return epgData;

  // Sort EPG data by 'since' time
  epgData.sort((a, b) => new Date(a.since) - new Date(b.since));

  for (let i = 1; i < epgData.length; i++) {
    const prevProgram = epgData[i - 1];
    const currentProgram = epgData[i];

    // Adjust the 'since' time of the current program to start 1 second after the previous program ends
    const newSince = new Date(new Date(prevProgram.till).getTime() + 1000);
    currentProgram.since = formatDateTime(newSince);

    // Ensure the duration is a valid number
    const duration = parseInt(currentProgram.duration);
    if (isNaN(duration)) {
      console.error(`Invalid duration for program at index ${i}:`, currentProgram);
      continue;
    }

    // Calculate the new 'till' time based on the duration
    const newTill = new Date(newSince);
    newTill.setSeconds(newTill.getSeconds() + duration);

    // Update the 'till' time of the current program
    currentProgram.till = formatDateTime(newTill);
  }

  // console.log(epgData);
  return epgData;
}

function formatDateTime(date) {
  const pad = (num) => (num < 10 ? "0" + num : num);
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
    date.getMinutes()
  )}:${pad(date.getSeconds())}`;
}

// const formatDateTime = (date) => {
//   const pad = (num) => (num < 10 ? "0" + num : num);
//   return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
//     date.getMinutes()
//   )}:${pad(date.getSeconds())}`;
// };
