import { Epg, Layout } from "@nessprim/planby-pro";
import { useApp } from "./useApp";
import { ChannelItem, CustomTimeline, DroppableArea, Line, LiveTime, Program } from "./components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BoxWithHandle } from "./components/BoxWithHandle";
// import GridCell from "./components/GridCell";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import { convertSeconds, formatTallyTime, getCookie } from "../../utils/Utils";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon, OverlineTitle, UserAvatar } from "../../components/Component";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ShimmerSimpleGallery, ShimmerTable } from "react-shimmer-effects";
import ScheduledChannelContext from "../ScheduledChannelContext";
import Swal from "sweetalert2";
import { commercialad, informalad, moviead, programad } from "../../images/epgcontent";
import classnames from "classnames";
import { toast } from "react-toastify";
import Tour from "reactour";
import addToscheduler from "../../images/flags/addToScheduler.png";
function StreamScheduler({
  selecteddate,
  isSchedules,
  epgJson1,
  setepgJson,
  setPlanEpg,
  newSince,
  setNewSince,
  planEpg,
  saveLoading,
  setSaveLoading,
  setRecentVideo,
  setPrograms,
  swapEdit,
  setSwapEdit,
  setIsLoading,
  setEpgJson,
  setSavedEpg,
  toDelete,
  setToDelete,
}) {
  const epgRef = useRef(null);
  const { selectedId } = useContext(ScheduledChannelContext);
  const [deletes, setDeletes] = useState(false);
  const [swap, setswap] = useState(false);
  const [zoomcout, setZoomcout] = useState(7200);
  const epgzoomfunction = (event) => {
    if (event === "zoomin") {
      setZoomcout((prevZoom) => prevZoom + 6000);
    } else if (event === "zoomout") {
      setZoomcout((prevZoom) => {
        const newZoom = prevZoom - 6000;
        return newZoom < 7200 ? 7200 : newZoom;
      });
    }
  };
  const [editedData, setEditedData] = useState(false);
  const { isLoading, elements, getEpgProps, getLayoutProps, removeElement, sinceTime, epgJson, onScrollToNow } = useApp(
    selecteddate,
    deletes,
    setDeletes,
    swap,
    setswap,
    zoomcout,
    editedData,
    setEditedData,
    // reset,
    // setReset,
    saveLoading,
    planEpg,
    sessionStorage.getItem("newSinceTime"),
    setNewSince,
    setRecentVideo,
    swapEdit,
    setSavedEpg,
    epgRef
  );
  // console.log('since time use app: ' + sinceTime);
  const [scheduledElements, setScheduledElements] = useState();

  const [loading, setLoading] = useState(false);
  const fetchScheduledVideo = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}show-from-scheduler`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", "null"),
        },
      });
      const responseData = await response.json();
      if (responseData.status) {
        setScheduledElements(responseData.data);
      }
      console.log(responseData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScheduledVideo();
  }, []);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setEpgJson(epgJson);
  }, [epgJson]);

  const removeFromScheduler = async (id) => {
    try {
      const formData = new FormData();
      formData.append("deleteSchedule", id);
      const response = await fetch(`${process.env.REACT_APP_API_URL}add-to-scheduler`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const responseData = await response.json();
      if (responseData.status) {
        toast.success(responseData.message);
        setScheduledElements((prevElements) => prevElements.filter((element) => element.video_id !== id));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [dndSet1, setDndSet1] = useState([]);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  useEffect(() => {
    setPlanEpg(dndSet1);
    // console.log(dndSet1);
  }, [dndSet1]);

  // useEffect(() => {
  //   if (epgJson && epgJson.length > 0) {
  //     setDndSet1(epgJson);
  //   }
  // }, [epgJson, selectedId, selecteddate]);

  useEffect(() => {
    // console.log("epgJson inner", epgJson);
    setepgJson(epgJson);
    if (epgJson && epgJson.length > 0) {
      const today = new Date().toISOString().split("T")[0];
      const filteredSet = epgJson.filter((item) => item.date === today && item.channel_id === String(selectedId));
      const sortedSet = filteredSet.sort((a, b) => new Date(a.since) - new Date(b.since));
      setDndSet1(sortedSet);
      // console.log(typeof selectedId);
    }
  }, [epgJson, selectedId, selecteddate]);

  // useEffect(() => {
  //   console.log("SelectedId: " + selectedId);
  // }, [selectedId]);

  // const handleOnDragEnd = (result) => {
  //   if (!result.destination) {
  //     return;
  //   }
  //   const items = Array.from(dndSet1);
  //   const [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);
  //   setDndSet1(items);
  // };
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(dndSet1);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Assuming `items` is already formatted correctly for backend
    try {
      // setSaveLoading(true);

      const response = await fetch(`${process.env.REACT_APP_API_URL}update-program-schedule`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authToken: getCookie("authToken", null),
        },
        body: JSON.stringify(items),
      });

      const responseData = await response.json();
      // setswap(true);
      // console.log(responseData);

      setDndSet1(responseData.data.data);
      setSwapEdit(responseData.data.data);
      // setEditedData(true);
      if (!responseData.status) {
        throw new Error("Failed to update schedule");
      }

      toast.success("Schedule updated successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update schedule");
    } finally {
      // setSaveLoading(false);
    }
  };

  const isLive = (since, till) => {
    const currentTime = new Date();
    const startDate = new Date(since);
    const endDate = new Date(till);
    return currentTime >= startDate && currentTime <= endDate;
  };
  // useEffect(() => {
  //   if (dndSet1.length > 0) {
  //     const sortedSet = [...dndSet1].sort((a, b) => new Date(a.since) - new Date(b.since));
  //     setDndSet1(sortedSet);
  //     console.log(sortedSet);
  //   }
  // }, [dndSet1]);
  const deleteProgram = (event, id, public_file_path) => {
    event.preventDefault();
    if (isSchedules) {
      Swal.fire({
        icon: "info",
        title: "Warning",
        text: "You Have Already Broadcasted This Program You Cant Delete It.",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setToDelete((prev) => [...prev, id]);
          setDndSet1((prev) => {
            const updatedSet = prev.filter((item) => item.id !== id);
            setSwapEdit(updatedSet);
            return updatedSet;
          });

          // await setPrograms();
          // const formData = new FormData();
          // formData.append("program_id", id);
          // formData.append("channel_id", selectedId);
          // formData.append("public_file_path", public_file_path);
          // const response = await fetch(`${process.env.REACT_APP_API_URL}delete-program`, {
          //   method: "POST",
          //   headers: {
          //     authToken: getCookie("authToken", null),
          //   },
          //   body: formData,
          // });
          // const responseData = await response.json();
          // if (responseData.status) {
          //   setSaveLoading(true);
          //   setDeletes(true);
          //   setTimeout(() => {
          //     setSaveLoading((prev) => prev && false);
          //   }, 100);
          // } else {
          //   setDndSet1((prev) => {
          //     const updatedSet = prev.filter((item) => item.id !== id);
          //     setSwapEdit(updatedSet);
          //     return updatedSet;
          //   });
          // }
          // setDeletes(true);
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
      // console.log(id);
    }
  };
  const [modalZoom, setModalZoom] = useState(false);
  const toggleZoom = () => setModalZoom(!modalZoom);
  const [tallyloading, setTallyLoading] = useState(false);
  const [tally, settally] = useState([]);
  const [durations, setDuration] = useState([]);
  const [title, setTitle] = useState("");
  const [radio, setRadio] = useState("programad");
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [primarynumber, setPrimarynumber] = useState();
  const [alerternateprimarynumber, setAlerternatePrimarynumber] = useState();
  //set Tally data
  const [tallyData, setTallyData] = useState([]);
  const [descriptionData, setdescriptionData] = useState();
  const setDescr = (e) => {
    setdescriptionData(e.target.value);
  };

  const getProgramData = async (id, action) => {
    console.log(id, action);

    const filteredData = dndSet1.filter((item) => item.id === id);
    const tallydatas = filteredData[0];
    console.log(tallydatas);
    settally(tallydatas);
    const duration = convertSeconds(tallydatas.duration);
    setDuration(duration);
    setTitle(tallydatas.title || "");
    setPrimarynumber(tallydatas.mobile_number || "");
    setAlerternatePrimarynumber(tallydatas.alt_number || "");
    setRadio(tallydatas.program_type || "programad");

    // const formData = new FormData();
    // formData.append("action", action);
    // formData.append("program_id", id);
    // formData.append("channel_id", selectedId);
    // try {
    //   setTallyLoading(true);
    //   const response = await fetch(`${process.env.REACT_APP_API_URL}tally-settings`, {
    //     method: "POST",
    //     headers: {
    //       authToken: getCookie("authToken", null),
    //     },
    //     body: formData,
    //   });
    //   const resposeData = await response.json();
    //   if (resposeData.status) {
    //     setTallyLoading(false);
    //     if (resposeData.data.is_data_saved) {
    //       // toast.success("OKOK");
    //       const filteredData = dndSet1.filter((item) => item.id === id);
    //       console.log(filteredData);
    //       const tallydatas = filteredData[0];
    //       settally(tallydatas);
    //       // console.log(tallydatas);
    //       const duration = convertSeconds(tallydatas.duration);
    //       setDuration(duration);
    //       // console.log(tally);
    //     } else {
    //       if (resposeData.data.programData) settally(resposeData.data.programData);
    //       else {
    //         const filteredData = dndSet1.filter((item) => item.id === id);
    //         console.log(filteredData);
    //         const tallydatas = filteredData[0];
    //         settally(tallydatas);
    //         const duration = convertSeconds(tallydatas.duration);
    //         setDuration(duration);
    //       }
    //       const duration = convertSeconds(resposeData.data.programData.duration);
    //       setDuration(duration);
    //     }

    //     // if()
    //   } else {
    //     setTallyLoading(false);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setTallyLoading(false);
    // }
  };
  const editEpgModal = (event, id, action) => {
    event.preventDefault();
    setActiveTab("1");
    getProgramData(id, action);
    toggleZoom();
  };

  const editTally = async (e) => {
    e.preventDefault();
    // const formData = new FormData();
    // formData.append("action", "updateOne");
    // formData.append("program_id", tally.id);
    // formData.append("channel_id", selectedId);
    // formData.append("program_name", tally.title);
    // formData.append("description", descriptionData);
    // formData.append("mobile_number", primarynumber);
    // formData.append("program_type", radio);
    // formData.append("alternate_primary_number", alerternateprimarynumber);
    try {
      // const response = await fetch(`${process.env.REACT_APP_API_URL}tally-settings`, {
      //   method: "POST",
      //   headers: {
      //     authToken: getCookie("authToken", null),
      //   },
      //   body: formData,
      // });
      // const resposeData = await response.json();
      // if (resposeData.status) {
      //   toast.success("Program Updated Successfully");
      //   setEditedData(true);
      //   setModalZoom(false);
      // } else {
      //   toast.error("Something went wrong");
      //   setEditedData(false);
      // }
      // console.log(resposeData);

      const updatedDndSet = dndSet1.map((program) => {
        if (program.id === tally.id) {
          return {
            ...program,
            title: title,
            description: descriptionData,
            mobile_number: primarynumber,
            alt_number: alerternateprimarynumber,
            program_type: radio,
          };
        }
        return program;
      });

      setDndSet1(updatedDndSet);
      setSwapEdit(updatedDndSet);
      console.log(updatedDndSet);
      // setEditedData(true);

      setModalZoom(false);
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();
  const navigateUpload = () => {
    navigate("/uploads");
  };
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState();
  const tourSteps = [
    {
      selector: ".addvideosTour",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            Start by adding videos to scheduler.
          </p>
          <img src={addToscheduler} alt="Upload video Tutorial" className="border border-2" />
        </>
      ),
    },
  ];
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div>
          <div className="bg-light epg-scheduler" ref={epgRef}>
            <div className="d-flex justify-content-between p-1 me-1 ms-1">
              <div>
                <Button className="btn-round me-2 px-2" color="primary" size="sm" onClick={() => onScrollToNow()}>
                  Go to Now
                </Button>
              </div>
              <div>
                <Button
                  className="btn-round btn-icon me-2"
                  color="primary"
                  size="sm"
                  onClick={() => epgzoomfunction("zoomin")}
                >
                  <Icon name="plus-sm" />
                </Button>
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  size="sm"
                  onClick={() => epgzoomfunction("zoomout")}
                >
                  <Icon name="minus-sm" />
                </Button>
              </div>
            </div>
            <DroppableArea removeElement={removeElement}>
              <Epg isLoading={isLoading} {...getEpgProps()} isLine="true">
                <Layout
                  {...getLayoutProps()} // renderLine={(props) => <Line {...props} />}
                  renderLine={(props) => <Line {...props} />}
                  renderCurrentTime={(props) => <LiveTime {...props} />}
                  // renderGridCell={(props) => <GridCell {...props} />}
                  renderTimeline={(props) => <CustomTimeline {...props} />}
                  renderProgram={({ program, ...rest }) => (
                    <Program
                      key={`${program.data.channelUuid}-${program.data.id}`}
                      program={program}
                      {...rest}
                      isedited={editedData}
                    />
                  )}
                  renderChannel={({ channel, ...rest }) => (
                    <ChannelItem key={channel.uuid} channel={channel} {...rest} className="border-1 border-light" />
                  )}
                />
              </Epg>
            </DroppableArea>
          </div>
          <div className="d-flex justify-content-end">
            <div className="border-end p-1 d-flex">
              <UserAvatar className="sq smallschedule epginfoboxes"></UserAvatar>
              <span className="ms-1"> &gt; than 2 Minutes</span>
            </div>
            <div className="border-end p-1 d-flex">
              <UserAvatar className="sq movieschedule epginfoboxes"></UserAvatar>
              <span className="ms-1"> Movies</span>
            </div>
            <div className="border-end p-1 d-flex">
              <UserAvatar className="sq infoschedule epginfoboxes"></UserAvatar>
              <span className="ms-1"> Informal</span>
            </div>
            <div className="border-end p-1 d-flex">
              <UserAvatar className="sq commercialschedule epginfoboxes"></UserAvatar>
              <span className="ms-1"> Commercials</span>
            </div>
          </div>
          <div className="row">
            <div class="video-label media-video-label mt-2 col-md-8">
              <span class="bg-secondary-dim">Media</span>
            </div>
            <div class="video-label media-video-label mt-2 col-md-4">
              <span class="bg-secondary-dim">Tally</span>
            </div>
          </div>
          <div className="row mt-1" style={{ maxHeight: "420px" }}>
            <div
              className="col-md-8 p-3 scheduledvideosscroll d-flex flex-wrap g-3 uploaded-videos"
              style={{ maxHeight: "400px", overflowY: "scroll" }}
            >
              {loading ? (
                <>
                  <ShimmerSimpleGallery card imageHeight={100} caption />;
                </>
              ) : (
                <>
                  {scheduledElements && scheduledElements.length > 0 ? (
                    <>
                      <div
                        className="position-relative mt-2 product-card col-md-3 shadow-sm card upload-videos"
                        onClick={(e) => navigateUpload(e)}
                      >
                        <div>
                          <div className="product-thumb scheduleuploadcard" style={{ height: "100px" }}>
                            <Icon name="upload-cloud" className="fs-3 text-dark" />
                            <p className="mt-1 text-dark">Upload Videos</p>
                          </div>
                        </div>
                        <div className="p-1" />
                      </div>
                      {scheduledElements.map((item) => (
                        <BoxWithHandle
                          key={item.id}
                          data={item}
                          sinceTime={sinceTime}
                          removeFromScheduler={removeFromScheduler}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      <p>
                        No Videos are scheduled yet{" "}
                        <span className="text-decoration-underline addvideosTour">
                          <Link to="/videos">Add Your Videos Now</Link>
                        </span>
                      </p>
                      <Tour
                        steps={tourSteps}
                        isOpen={isTourOpen}
                        accentColor="#e14954"
                        getCurrentStep={(curr) => setCurrentStep(curr)}
                        onRequestClose={() => setIsTourOpen(false)}
                        showDots={false}
                        prevButton={
                          <button
                            className="btn btn-secondary"
                            callbackFunc={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          >
                            Prev
                          </button>
                        }
                        nextButton={
                          <button
                            className="btn btn-primary"
                            callbackFunc={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          >
                            Next
                          </button>
                        }
                        lastStepNextButton={
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/videos");
                              setIsTourOpen(false);
                              return false;
                            }}
                          >
                            Go To Media Library
                          </button>
                        }
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div className="col-md-4 p-3 mt-1 tallyscroll" style={{ maxHeight: "400px", overflowY: "scroll" }}>
              {epgJson.length > 0 ? (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="droppable1">
                    {(provided) => (
                      <div ref={provided.innerRef} className="col-sm-12 d-flex justify-content-center text-dark">
                        <div className="h-100 w-100" style={{ fontSize: "12px" }}>
                          {dndSet1.map((item, index) => {
                            let newClassName = "";
                            if (item.duration < 120 && (!item.program_type || item.program_type === "programad")) {
                              newClassName += "lessdurationprogram " + item.program_type;
                            } else {
                              newClassName += item.program_type + " ";
                            }
                            return (
                              <Draggable draggableId={item.id} key={item.id} index={index}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`p-3 bg-white tallycontainer border border-light round-lg mb-3 d-flex justify-content-between align-items-center text-white ${newClassName}`}
                                  >
                                    <div className="d-flex align-items-center">
                                      <UserAvatar
                                        theme="primary"
                                        image={item.image}
                                        className="sq tallyavatar"
                                      ></UserAvatar>
                                      <span className="ms-2 me-2 tallytitle text-dark">{item.title}</span>
                                    </div>
                                    <div className="d-flex timestarttally flex-column" style={{ whiteSpace: "nowrap" }}>
                                      <span className="me-2 text-dark timestart">Time Start</span>
                                      <span className="text-dark">{formatTallyTime(item.since)}</span>
                                    </div>
                                    <div className="me-2 ms-2">
                                      <UncontrolledDropdown>
                                        <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
                                          <Icon name="more-v"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <ul className="link-list-opt">
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#links"
                                                onClick={(e) => editEpgModal(e, item.id, "fetchone")}
                                              >
                                                <Icon name="edit-alt"></Icon>
                                                <span>Edit</span>
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#links"
                                                onClick={(e) => deleteProgram(e, item.id, item.public_file_path)}
                                              >
                                                <Icon name="trash"></Icon>
                                                <span>Delete</span>
                                              </DropdownItem>
                                            </li>
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : epgJson.length === 0 ? (
                <p>No planned Program</p>
              ) : (
                <ShimmerTable row={5} />
              )}
            </div>
          </div>
        </div>
      </DndProvider>
      {/* Epgg content modal */}
      <Modal isOpen={modalZoom} toggle={toggleZoom} modalClassName="zoom" size="lg">
        <ModalHeader
          toggle={toggleZoom}
          className="d-flex justify-content-between"
          close={
            <button className="close" onClick={toggleZoom}>
              <Icon name="cross" />
            </button>
          }
        >
          EPG Information
        </ModalHeader>
        <ModalBody className="">
          {/* navtabs */}

          <Nav tabs className="mt-n3">
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "1" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                1. <br />
                EPG Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "2" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                2. <br />
                EPG Content
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {tallyloading ? (
                <ShimmerTable row={3} />
              ) : (
                <Row>
                  <Col sm="6">
                    <div className="form-group">
                      <Label htmlFor="tallyname" className="form-label">
                        Name
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          className="form-control"
                          type="text"
                          id="tallyname"
                          value={title}
                          maxLength={50}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="form-group">
                      <Label htmlFor="streampath" className="form-label">
                        Stream
                      </Label>
                      <div className="form-control-wrap">
                        <input className="form-control" type="text" id="streampath" readOnly value={tally.file_path} />
                      </div>
                    </div>
                  </Col>
                  <Col sm="6" className="mt-1">
                    <div className="form-group">
                      <Label htmlFor="default-textarea" className="form-label">
                        Description
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          className="no-resize form-control"
                          type="textarea"
                          id="default-textarea"
                          value={descriptionData}
                          onChange={(e) => setDescr(e)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="6" className="mt-1">
                    <div className="form-group">
                      <Label htmlFor="default-textarea" className="form-label">
                        Duration
                      </Label>
                      <div className="d-flex justify-between">
                        <span className="d-flex align-items-center">
                          Hour{" "}
                          <input
                            className="form-control ms-1 text-center"
                            type="text"
                            style={{ width: "50px" }}
                            readOnly
                            value={durations.hours}
                          />
                        </span>
                        <span className="d-flex align-items-center">
                          Minutes{" "}
                          <input
                            className="form-control ms-1 text-center"
                            type="text"
                            style={{ width: "50px" }}
                            readOnly
                            value={durations.minutes}
                          />
                        </span>
                        <span className="d-flex align-items-center">
                          Seconds{" "}
                          <input
                            className="form-control ms-1 text-center"
                            type="text"
                            style={{ width: "50px" }}
                            readOnly
                            value={durations.seconds}
                          />
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tabId="2">
              <Row className="g-3">
                <Col md="3" className="col-6">
                  <div className="preview-block">
                    <OverlineTitle className="preview-title">Program</OverlineTitle>
                    <div className="custom-control custom-radio image-control">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="programad"
                        checked={radio === "programad" ? true : false}
                        onChange={(ev) => setRadio("programad")}
                      />
                      <label className="custom-control-label" htmlFor="programad">
                        <img src={programad} alt="" />
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md="3" className="col-6">
                  <div className="preview-block">
                    <OverlineTitle className="preview-title">Commercial</OverlineTitle>
                    <div className="custom-control custom-radio image-control">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="commercialad"
                        checked={radio === "commercialad" ? true : false}
                        onChange={(ev) => setRadio("commercialad")}
                      />
                      <label className="custom-control-label" htmlFor="commercialad">
                        <img src={commercialad} alt="" />
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md="3" className="col-6">
                  <div className="preview-block">
                    <OverlineTitle className="preview-title">Informal</OverlineTitle>
                    <div className="custom-control custom-radio image-control">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="informalad"
                        checked={radio === "informalad" ? true : false}
                        onChange={(ev) => setRadio("informalad")}
                      />
                      <label className="custom-control-label" htmlFor="informalad">
                        <img src={informalad} alt="" />
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md="3" className="col-6">
                  <div className="preview-block">
                    <OverlineTitle className="preview-title">Movie</OverlineTitle>
                    <div className="custom-control custom-radio image-control">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="moviead"
                        checked={radio === "moviead" ? true : false}
                        onChange={(ev) => setRadio("moviead")}
                      />
                      <label className="custom-control-label" htmlFor="moviead">
                        <img src={moviead} alt="" />
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className={`mt-1 ${radio === "commercialad" || radio === "informalad" ? "" : "d-none"}`}>
                <Col sm="6">
                  <div className="form-group">
                    <Label htmlFor="default-0" className="form-label">
                      Primary phone number
                    </Label>
                    <div className="form-control-wrap">
                      {/* <input className="form-control bg-black" type="text" id="default-0" placeholder="+19999999999" /> */}
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        className="primarynumberinput"
                        value={primarynumber}
                        onChange={setPrimarynumber}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <Label htmlFor="default-0" className="form-label">
                      Alternate Phone number(optional)
                    </Label>
                    <div className="form-control-wrap">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        className="primarynumberinput"
                        value={alerternateprimarynumber}
                        onChange={setAlerternatePrimarynumber}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <div className="d-flex justify-content-end mt-1">
            {activeTab === "2" ? (
              <Button
                color="primary"
                className="me-1"
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                Prev
              </Button>
            ) : (
              <></>
            )}
            {activeTab === "1" ? (
              <Button
                color="primary"
                className="me-1"
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                Next
              </Button>
            ) : (
              <Button color="primary" className="me-1" onClick={(e) => editTally(e)}>
                Submit
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default StreamScheduler;
